import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../../core/classes/AppContext";
import { IComponentProps } from "../interfaces/IComponentProps";
import { AppViews } from "../../core/enumerations/AppViews";
import { AppActions } from "../../core/enumerations/AppActions";
import { AppActionHelpers } from "../../core/classes/Helpers";
// @ts-ignore
import logo from "../../../assets/img/solveiq/logoWhite.png";

export interface IHeaderBarProps extends IComponentProps {
    showLogoff: boolean;
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter,
}))
@observer
export class HeaderBar extends React.Component<Partial<IHeaderBarProps>>
{
    constructor(props) {
        super(props);

        this.onLogoffClick = this.onLogoffClick.bind(this);
        this.onYourAccountClicked = this.onYourAccountClicked.bind(this);
    }

    public render() {

        const showLogoff = this.props.showLogoff;
        const isLoggedIn = this.props.viewStore.isLoggedIn;
        const currentScreen = this.getCurrentScreenString(this.props.viewStore.activeScreen);

        try {
            return <div className="headerBar secondaryFont">
                <div className="wrapper">
                    <img className="logo" src={logo} />
                    <h1 className="mainHeader">{currentScreen}</h1>
                    {isLoggedIn && <div className="userInfo">
                        <h3 className="userName">{this.props.viewStore.userFullName}</h3>
                        <a className="customerStatus" onClick={this.onYourAccountClicked}>Your Account</a>
                        {showLogoff &&
                            <h3 className="logOff" onClick={this.onLogoffClick}>Log Off</h3>
                        }
                    </div>}
                </div>
            </div>
        } catch (ex) {
            let outerex = new Error(`Error Rendering HeaderBar - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportCriticalException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private onLogoffClick() {
        const action = AppActionHelpers.CreateAction(AppActions.LOGOFF, undefined);
        this.props.dispatcher.DispatchAction(action);
    }

    private onYourAccountClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.SubscriptionDetail);
        this.props.dispatcher.DispatchAction(action);
    }

    private getCurrentScreenString(screen: AppViews) {
        let screenString: string;
        switch (screen) {
            case AppViews.Overview:
                screenString = "Your Account";
                break;
            case AppViews.SubscriptionDetail:
                screenString = "Subscriptions & Billing";
                break;
            case AppViews.UpdatePayment:
            case AppViews.UpdatePaymentAndResume:
                screenString = "Update your payment method";
                break;
            case AppViews.SelectPayment:
                screenString = "Select your payment method";
                break;
            case AppViews.CancelSubscription:
            case AppViews.CancelSubscription2:
            case AppViews.CancelSubscription3:
            case AppViews.CancelSubscription4:
            case AppViews.CancelSubscription5:
                screenString = "Cancel your subscription";
                break;
            case AppViews.Support:
            case AppViews.QuestionAboutBill:
            case AppViews.InstallDriverProblem:
            case AppViews.SoftwareInstallProblem:
            case AppViews.DriverQuestionForm:
            case AppViews.MessageSent:
            case AppViews.RegistrationKeyForm:
            case AppViews.RequestARefundForm:
            case AppViews.ContactForm:
                screenString = "Contact Us";
                break;
            case AppViews.Login:
                screenString = "Log In";
                break;
            case AppViews.ResetPassword:
                screenString = "Reset Password";
                break;
            case AppViews.KnowledgeBase:
                screenString = "Knowledge Base";
                break;
            case AppViews.ForgotPassword:
                screenString = "Forgot Password";
                break;
        }
        return screenString;
    }
}