import { inject, injectable } from "inversify";
import { TYPES } from "../../core/enumerations/TYPES";
import { IStoreController } from "../interfaces/IStoreController";
import { IViewStore } from "../interfaces/IViewStore";

@injectable()
export class StoreController implements IStoreController {

    public ViewStore: IViewStore;

    constructor(@inject(TYPES.ViewStore) viewStore: IViewStore) {

        this.ViewStore = viewStore

        
    }
}
