import * as React from "react";
// @ts-ignore
import loader from "../../../assets/img/loader.gif";

interface ILoadingViewProps { loadingMessage: string }

export class LoadingView extends React.Component<ILoadingViewProps, undefined>{
    public render() {
        return <div className="loadingScreenWrapper" id="loadingScreenWrapper">
            <img className="loadingScreen" id="loadingScreen" src={loader} />
            <p className="loadingScreenText" id="loadingScreenText">
                {this.props.loadingMessage}
            </p>
        </div>;
    }
}