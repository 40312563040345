export class BrowserDetection {

    // NOTE: It's generally considered "better" to determine browser via feature detection instead
    // of interrogating the user-agent string (since that can be spoofed). However this creates a bit
    // of a moving target for browser detection so likely this code will need to be updated periodcally.
    // 
    // pulled from here: https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome
    public static isChrome(): boolean {

        // please note, 
        // that IE11 now returns undefined again for window.chrome
        // and new Opera 30 outputs true for window.chrome
        // but needs to check if window.opr is not undefined
        // and new IE Edge outputs to true now for window.chrome
        // and if not iOS Chrome check
        // so use the below updated condition
        var isChromium = (window as any).chrome;
        var winNav = window.navigator;
        var vendorName = winNav.vendor;
        var isOpera = typeof (window as any).opr !== "undefined";
        var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
        var isIOSChrome = winNav.userAgent.match("CriOS");

        if (isIOSChrome) {
            // is Google Chrome on IOS
            return true;
        } else if (
            isChromium !== null &&
            typeof isChromium !== "undefined" &&
            vendorName === "Google Inc." &&
            isOpera === false &&
            isIEedge === false
        ) {
            return true;
        } else {
            return false;
        }
    }
}