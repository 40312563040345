import * as React from "react";
// @ts-ignore
import operator from "../../assets/img/operator.png";

declare var supportPhoneNumber: string;

export const ContactPane: React.StatelessComponent = () => {
    return <div id="contact_pane" className="contact_pane overview_pane">
        <img id="contact_pane_image" src={operator} />
        <div id="contact_pane_content_container" className="contact_pane_content_container">
            <h2 id="contact_pane_header_text" className="contact_pane_header_text">Need help with your software or other technical issues?</h2>
            <div id="contact_pane_spacer" className="contact_pane_spacer" />
            <h3 id="contact_pane_subheader_text" className="contact_pane_subheader_text">Your purchase includes direct access to our technicians at <span className="contact_pane_phone">{supportPhoneNumber}</span></h3>
        </div>
    </div>
};