import { ICallbackPayload, IDispatcher } from "driversupport.frontend.common";
import { inject, injectable, postConstruct } from "inversify";
import { action, runInAction } from "mobx";
import { IAuthApi } from "../../api/interfaces/IAuthApi";
import { IExceptionReporter } from "../../api/interfaces/IExceptionReporter";
import { IUIApi } from "../../api/interfaces/IUIApi";
import { AppActions } from "../../core/enumerations/AppActions";
import { AppViews } from "../../core/enumerations/AppViews";
import { TYPES } from "../../core/enumerations/TYPES";
import { IViewStore } from "../../store/interfaces/IViewStore";
import { IKnowledgeBaseObserver } from "../interfaces/IKnowledgeBaseObserver";

@injectable()
export class KnowledgeBaseObserver implements IKnowledgeBaseObserver {

    private readonly viewStore: IViewStore;
    private readonly authApi: IAuthApi;
    private readonly uiApi: IUIApi;
    private readonly exceptionReporter: IExceptionReporter;
    private readonly dispatcher: IDispatcher<AppViews>;

    constructor(@inject(TYPES.ViewStore) viewStore: IViewStore,
        @inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter,
        @inject(TYPES.AuthApi) authApi: IAuthApi,
        @inject(TYPES.UIApi) uiApi: IUIApi,
        @inject(TYPES.Dispatcher) dispatcher: IDispatcher<AppViews>) {

        this.viewStore = viewStore;
        this.authApi = authApi;
        this.uiApi = uiApi;
        this.exceptionReporter = exceptionReporter;
        this.dispatcher = dispatcher;

        this.dispatcher.Register(this.dispatcherCallback.bind(this));
    }

    // this is called when inversify creates an instance of this class
    @postConstruct()
    @action
    public async init() {
        try {
            this.viewStore.activeScreen = AppViews.KnowledgeBase;
            this.viewStore.isLoggedIn = false;
        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            runInAction(() => {
                this.viewStore.isError = true;
            });
        }
    }

    private dispatcherCallback(payload: ICallbackPayload<AppActions>): void {
        try {
            switch (payload.action.actionType) {

                
            }
        } catch (ex) {
            const error = new Error("Error processing app action in DispatcherCallback() in KnowledgeBaseObserver");
            this.exceptionReporter.ReportCriticalException(error);
        }
    }
}
