//export interface IContactModel {
//    Name: string;
//    Email: string;
//    Message: string;
//    SupportTopic: string;
//}

export class ContactModel {
    public Name: string;
    public Email: string;
    public Message: string;
    public SupportTopic: string;
}