import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { ContactModel } from "../models/ContactModel";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class RegistrationKeyForm extends React.Component<Partial<IComponentProps>>{
    private emailInput: JQuery;

    constructor(props) {
        super(props);

        this.sendRegistrationKeyRequest = this.sendRegistrationKeyRequest.bind(this);
        this.onBackClicked = this.onBackClicked.bind(this);
    }

    public render() {

        const isLocked = this.props.viewStore.isUILocked;
        const error = this.props.viewStore.contactError;

        let opts: Object = {};
        if (isLocked) {
            opts['disabled'] = 'disabled';
        }

        return <div className="wrapper contactFormWrapper">
            <form className="appPanel registrationKeyForm contactForm defaultMaterial" autoComplete="false">
                <h2 className="panelTitle">Request Registration Key</h2>
                <p className="contactSubHeader">Use the form below to have your registration key emailed to you</p>
                <div className="contactFieldsWrapper">
                    <p className="contactFieldLabel">Email:</p>
                    <input {...opts} className="contactField registrationKeyField" type="text" name="Email" ref={(input) => { this.emailInput = $(input); }} />
                </div>
                {isLocked &&
                    <div className="miniLoader">
                        <div className="loaderImage" />
                        <p className="miniLoaderText">Sending key...</p>
                    </div>
                }
                {error &&
                    <div className="miniErrorWrapper">
                        <div className="errorImage" />
                        <p className="miniErrorText">Error sending key</p>
                    </div>
                }
                <input type="submit" className="contactSubmitButton appButton" value="Send" />
                <div className="backButton appButton" onClick={this.onBackClicked.bind(this)}>Go Back</div>
            </form>
        </div>;
    }

    public componentDidMount() {
        this.Initialize();
    }

    private Initialize() {
        $('.registrationKeyForm input[type="text"]').tooltipster({
            trigger: 'custom', // default is 'hover' which is no good here
            onlyOne: false, // allow multiple tips to be open at a time
            position: 'right', // display the tips to the right of the element
            theme: 'error-tooltip'
        });
        this.ValidationInit();
    }

    private ValidationInit() {
        $('.registrationKeyForm').validate({
            errorPlacement: function (error, element) {
                var lastError = $(element).data('lastError'),
                    newError = $(error).text();

                $(element).data('lastError', newError);

                if (newError !== '' && newError !== lastError) {
                    $(element).tooltipster('content', newError);
                    $(element).tooltipster('open');
                }
            },
            rules: {
                Email: { required: true, email: true },
            },
            submitHandler: () => {
                if (this.props.viewStore.isUILocked) {
                    return;
                }
                this.sendRegistrationKeyRequest();
            },
            success: function (label, element) {
                $(element).tooltipster('close');
            },
            messages: {
                Email: "Please enter a valid email",
            }
        })
    }

    private onBackClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.Support);
        this.props.dispatcher.DispatchAction(action);
    }

    private sendRegistrationKeyRequest() {

        const payload = new ContactModel();

        payload.Email = this.emailInput.val().toString();
        payload.Name = "Unknown";
        payload.Message = "Request my registration key";
        payload.SupportTopic = "Request my registration key";

        const action = AppActionHelpers.CreateAction(AppActions.CONTACT, payload);
        this.props.dispatcher.DispatchAction(action);
    }
}