import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../../core/classes/AppContext";
import { ViewUtils } from "../../core/classes/ViewUtils";
import { AppViews } from "../../core/enumerations/AppViews";
import { IComponentProps } from "../interfaces/IComponentProps";
import { MainMenuItem } from "./MainMenuItem";
import { AppActionHelpers } from "../../core/classes/Helpers";
import { AppActions } from "../../core/enumerations/AppActions";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter,
}))
@observer
export class MainMenu extends React.Component<Partial<IComponentProps>, undefined>
{
    constructor(props) {
        super(props);

        this.handleMenuItemClick = this.handleMenuItemClick.bind(this);
    }


    public render() {
        try {

            const isLoggedIn = this.props.viewStore.isLoggedIn;
            const activeScreen = this.props.viewStore.activeScreen;
            const hasRecurringSub = (this.props.viewStore.user) ? this.props.viewStore.user.HasRecurringSubscription : false;

            return <ul className="mainMenu">
                <div className="wrapper">
                    <div className="menuItems">
                        {isLoggedIn &&
                            <MainMenuItem isActive={activeScreen === AppViews.Overview}
                                text="Overview"
                                mapToScreen={AppViews.Overview}
                                onClick={() => this.handleMenuItemClick(AppViews.Overview)}
                            />}
                        <MainMenuItem isActive={ViewUtils.isSupportScreen(activeScreen)}
                            text="Support"
                            mapToScreen={AppViews.Support}
                            onClick={() => this.handleMenuItemClick(AppViews.Support)} />
                        {!isLoggedIn &&
                            <MainMenuItem isActive={activeScreen === AppViews.Login}
                                text="Log in"
                                mapToScreen={AppViews.Login}
                                onClick={() => this.handleMenuItemClick(AppViews.Login)} />
                        }
                    </div>
                </div>
            </ul>
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering MainMenu ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportCriticalException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private handleMenuItemClick(mapToScreen: AppViews) {
        if (this.props.viewStore.isUILocked) {
            return;
        }
        if (mapToScreen != this.props.viewStore.activeScreen) {
            const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, mapToScreen);
            this.props.dispatcher.DispatchAction(action);
        }
    }
}