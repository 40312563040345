import * as React from "react";
import { IExceptionReporter } from "../../api/interfaces/IExceptionReporter";

interface IErrorBoundaryState {
    hasError: boolean;
}

interface IErrorBoundaryProps {
    ExceptionReporter: IExceptionReporter;
}

// We have to do this because react doesn't export the ErrorInfo type. Stupid.
export interface IHandleErrorInfo {
    componentStack: string;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, Partial<IErrorBoundaryState>> {

    private exceptionReporter: IExceptionReporter;

    constructor(props) {
        super(props);
        this.state = { hasError: false };
        this.exceptionReporter = this.props.ExceptionReporter;
    }

    public componentDidCatch(error: Error, info: IHandleErrorInfo) {
        // Display fallback UI
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        this.exceptionReporter.ReportCriticalException(error);
    }

    public render(): JSX.Element {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }
        return <div className="errorBoundary">{this.props.children}</div>;
    }
}