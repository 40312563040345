import { Dispatcher, IDispatcher } from "driversupport.frontend.common";
import { Container } from "inversify";
import { AuthApi } from "../../api/classes/AuthApi";
import { ExceptionReporter } from "../../api/classes/ExceptionReporter";
import { SupportApi } from "../../api/classes/SupportApi";
import { UIApi } from "../../api/classes/UIApi";
import { IAuthApi } from "../../api/interfaces/IAuthApi";
import { IExceptionReporter } from "../../api/interfaces/IExceptionReporter";
import { ISupportApi } from "../../api/interfaces/ISupportApi";
import { IUIApi } from "../../api/interfaces/IUIApi";
import { AppActions } from "../../core/enumerations/AppActions";
import { TYPES } from "../../core/enumerations/TYPES";
import { ForgotPasswordObserver } from "../../observers/classes/ForgotPasswordObserver";
import { KnowledgeBaseObserver } from "../../observers/classes/KnowledgeBaseObserver";
import { ForgotPasswordObserverController, KnowledgeBaseObserverController, ObserverController, ResetPasswordObserverController } from "../../observers/classes/ObserverController";
import { ResetPasswordObserver } from "../../observers/classes/ResetPasswordObserver";
import { ViewObserver } from "../../observers/classes/ViewObserver";
import { IForgotPasswordObserver } from "../../observers/interfaces/IForgotPasswordObserver";
import { IKnowledgeBaseObserver } from "../../observers/interfaces/IKnowledgeBaseObserver";
import { IObserverController } from "../../observers/interfaces/IObserverController";
import { IResetPasswordObserver } from "../../observers/interfaces/IResetPasswordObserver";
import { IViewObserver } from "../../observers/interfaces/IViewObserver";
import { StoreController } from "../../store/classes/StoreController";
import { ViewStore } from "../../store/classes/ViewStore";
import { IStoreController } from "../../store/interfaces/IStoreController";
import { IViewStore } from "../../store/interfaces/IViewStore";
import { CancelSubscriptionObserver } from "../../observers/classes/CancelSubscriptionObserver";


export class TypeContainerFactory {

    public static GetContainer(): Container {
        const container = new Container();

        // general
        container.bind<IExceptionReporter>(TYPES.ExceptionReporter).to(ExceptionReporter).inSingletonScope();
        container.bind<IDispatcher<AppActions>>(TYPES.Dispatcher).to(Dispatcher).inSingletonScope();

        // apis
        container.bind<IAuthApi>(TYPES.AuthApi).to(AuthApi).inSingletonScope();
        container.bind<IUIApi>(TYPES.UIApi).to(UIApi).inSingletonScope();
        container.bind<ISupportApi>(TYPES.SupportApi).to(SupportApi).inSingletonScope();

        // stores
        container.bind<IStoreController>(TYPES.StoreController).to(StoreController).inSingletonScope();
        container.bind<IViewStore>(TYPES.ViewStore).to(ViewStore).inSingletonScope();

        // observers
        container.bind<IObserverController>(TYPES.ObserverController).to(ObserverController).inSingletonScope();
        container.bind<IViewObserver>(TYPES.ViewObserver).to(ViewObserver).inSingletonScope();
        container.bind<ICancelSubscriptionObserver>(TYPES.CancelSubscriptionObserver).to(CancelSubscriptionObserver).inSingletonScope();

        return container;
    }


    public static GetResetPasswordContainer(): Container {
        const container = new Container();

        // general
        container.bind<IExceptionReporter>(TYPES.ExceptionReporter).to(ExceptionReporter).inSingletonScope();
        container.bind<IDispatcher<AppActions>>(TYPES.Dispatcher).to(Dispatcher).inSingletonScope();

        // apis
        container.bind<IAuthApi>(TYPES.AuthApi).to(AuthApi).inSingletonScope();
        container.bind<IUIApi>(TYPES.UIApi).to(UIApi).inSingletonScope();
        container.bind<ISupportApi>(TYPES.SupportApi).to(SupportApi).inSingletonScope();

        // stores
        container.bind<IStoreController>(TYPES.StoreController).to(StoreController).inSingletonScope();
        container.bind<IViewStore>(TYPES.ViewStore).to(ViewStore).inSingletonScope();

        // observers
        container.bind<IObserverController>(TYPES.ObserverController).to(ResetPasswordObserverController).inSingletonScope();
        container.bind<IResetPasswordObserver>(TYPES.ResetPasswordObserver).to(ResetPasswordObserver).inSingletonScope();

        return container;
    }

    public static GetForgotPasswordContainer(): Container {
        const container = new Container();

        // general
        container.bind<IExceptionReporter>(TYPES.ExceptionReporter).to(ExceptionReporter).inSingletonScope();
        container.bind<IDispatcher<AppActions>>(TYPES.Dispatcher).to(Dispatcher).inSingletonScope();

        // apis
        container.bind<IAuthApi>(TYPES.AuthApi).to(AuthApi).inSingletonScope();
        container.bind<IUIApi>(TYPES.UIApi).to(UIApi).inSingletonScope();
        container.bind<ISupportApi>(TYPES.SupportApi).to(SupportApi).inSingletonScope();

        // stores
        container.bind<IStoreController>(TYPES.StoreController).to(StoreController).inSingletonScope();
        container.bind<IViewStore>(TYPES.ViewStore).to(ViewStore).inSingletonScope();

        // observers
        container.bind<IObserverController>(TYPES.ObserverController).to(ForgotPasswordObserverController).inSingletonScope();
        container.bind<IForgotPasswordObserver>(TYPES.ForgotPasswordObserver).to(ForgotPasswordObserver).inSingletonScope();

        return container;
    }

    public static GetKnowledgeBaseContainer(): Container {
        const container = new Container();

        // general
        container.bind<IExceptionReporter>(TYPES.ExceptionReporter).to(ExceptionReporter).inSingletonScope();
        container.bind<IDispatcher<AppActions>>(TYPES.Dispatcher).to(Dispatcher).inSingletonScope();

        // apis
        container.bind<IAuthApi>(TYPES.AuthApi).to(AuthApi).inSingletonScope();
        container.bind<IUIApi>(TYPES.UIApi).to(UIApi).inSingletonScope();
        container.bind<ISupportApi>(TYPES.SupportApi).to(SupportApi).inSingletonScope();

        // stores
        container.bind<IStoreController>(TYPES.StoreController).to(StoreController).inSingletonScope();
        container.bind<IViewStore>(TYPES.ViewStore).to(ViewStore).inSingletonScope();

        // observers
        container.bind<IObserverController>(TYPES.ObserverController).to(KnowledgeBaseObserverController).inSingletonScope();
        container.bind<IKnowledgeBaseObserver>(TYPES.KnowledgeBaseObserver).to(KnowledgeBaseObserver).inSingletonScope();
        return container;
    }
}