export enum AppActions {
    CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
    CHANGE_ACTIVE_SUBSCRIPTION = "CHANGE_ACTIVE_SUBSCRIPTION",
    GET_EXPRESS_CHECKOUT_URL = "GET_EXPRESS_CHECKOUT_URL",
    LOGIN = "LOGIN",
    LOGOFF = "LOGOFF",
    REFRESH_UI = "REFRESH_UI",
    RESUME_SUBSCRIPTION = "RESUME_SUBSCRIPTION",
    SELECT_ACTIVE_SCREEN = "SELECT_ACTIVE_SCREEN",
    CONTACT_FORM_BACK = "CONTACT_FORM_BACK",
    SELECT_OVERVIEW_SCREEN = "SELECT_OVERVIEW_SCREEN",
    SELECT_FORGOT_PASSWORD_SCREEN = "SELECT_FORGOT_PASSWORD_SCREEN",
    UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD",
    UPDATE_USER_INFO = "UPDATE_USER_INFO",
    VIEW_INVOICES = "VIEW_INVOICES",
    RESET_PASSWORD = "RESET_PASSWORD",
    FORGOT_PASSWORD = "FORGOT_PASSWORD",
    SEND_PASSWORD_RESET_EMAIL = "SEND_PASSWORD_RESET_EMAIL",
    RESEND_FORGOT_PASSWORD = "RESEND_FORGOT_PASSWORD",
    CONTACT = "CONTACT",
    CANCEL_SUBSCRIPTION_ADVANCE = "CANCEL_SUBSCRIPTION_ADVANCE",
    CANCEL_SUBSCRIPTION_BACK = "CANCEL_SUBSCRIPTION_BACK",
    DISCOUNT_SUBSCRIPTION = "DISCOUNT_SUBSCRIPTION",
    DEACTIVATE_CURRENT_MACHINE = "DEACTIVATE_CURRENT_MACHINE",
    LAUNCH_PAGE_BOOST = "LAUNCH_PAGE_BOOST",
    SUBMIT_CANCEL_FEEDBACK = "SUBMIT_CANCEL_FEEDBACK",
    DOWNLOAD_DSONE = "DOWNLOAD_DSONE",
    OPEN_INVOICE = "OPEN_INVOICE",
    SELECT_ACTIVE_MACHINE = "SELECT_ACTIVE_MACHINE",
    LAUNCH_CART = "LAUNCH_CART",
    SHOW_ERROR_MODAL = "SHOW_ERROR_MODAL",
    CLOSE_ERROR_MODAL = "CLOSE_ERROR_MODAL",
}
