/* tslint:disable:max-classes-per-file */
import { IAction } from "driversupport.frontend.common";
import { AppActions } from "../enumerations/AppActions";

export class AppActionHelpers {
    public static CreateAction(actionType: AppActions, data: any, token?: string, ): IAction<AppActions> {
        return { actionType, data, token };
    }
}


export class FormatHelpers {
    public static convertBytesToDisplay(bytes: number, useSIUnits: boolean): string {
        if (bytes != null) {
            return humanFileSize(bytes, useSIUnits);
        }

        throw new Error(`Invalid argument: bytes - ${bytes}. Number must be defined`);
    }

    public static converDateToDisplay(date: Date): string {
        return date.toLocaleDateString();
    }

    public static convertNumToDisplay(numToConvert: number): string {
        if (numToConvert != null) {

            if (numToConvert > 999) {
                return "999";
            }
            return numToConvert.toString();
        }
    }

    public static convertBytesToMB(numToConvert: number): string {
        if (numToConvert != null) {
            if (numToConvert === 0) {
                return "0"
            }
            return (numToConvert / 1000000).toFixed();
        }

        throw new Error(`Invalid argument: numToConvert - ${numToConvert}. Number must be defined`);
    }

    public static leftPadZeros(num: number, digits: number): string {
        let s = num.toString();
        while (s.length < digits) {
            s = "0" + s;
        }
        return s;
    }
}


function humanFileSize(bytes: number, si: boolean): string {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    //var units = si
    //    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    //    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    // always use SI units because RAM manufacturers suck
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
}