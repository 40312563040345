import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { CancelFeedbackModel } from "../models/CancelFeedbackModel";
import { ISubscriptionViewModel } from "../models/ISubscriptionViewModel";
import { ContactPane } from "../overview/ContactPane";
import { CancelProgressBar } from "./CancelProgressBar";
import { IAction } from "driversupport.frontend.common";

declare var _kmq;

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class CancelSubscription5 extends React.Component<Partial<IComponentProps>, undefined>{
    private selectedSubscription: ISubscriptionViewModel;
    private subscriptionIsDiscounted: boolean;
    private ReasonType: JQuery;

    constructor(props) {
        super(props);
        this.onEndBenefitsClicked = this.onEndBenefitsClicked.bind(this);
        this.onKeepBenefitsClicked = this.onKeepBenefitsClicked.bind(this);
        this.selectedSubscription = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions.find((sub: ISubscriptionViewModel) => sub.SubscriptionId == this.props.viewStore.activeSubscriptionId);
        this.subscriptionIsDiscounted = parseFloat(this.selectedSubscription.SubscriptionPrice) < 9.9;

        _kmq.push(['record', "Cancel Step 5"]);
    }

    public render() {
        try {
            const price = parseFloat(this.selectedSubscription.SubscriptionPrice);
            const discountPrice = (price / 2).toString().match(/^\d+(?:\.\d{0,2})/)[0]; //truncate at 2 decimal places
            const discountString = "$" + this.selectedSubscription.SubscriptionPrice.replace(price.toString(), discountPrice);

            return <div className="cancelSubscriptionWrapper" id="cancelSubscriptionWrapper">
                <CancelProgressBar step={5} />
                <ContactPane />

                <form id="cancelFinalForm">
                    <div className="cancelFinalForm appPanel defaultMaterial">
                        <h2 className="cancelFinalFormHeader" id="cancelFinalFormHeader">We are really sorry to see you leave.</h2>
                        <p className="cancelFinalFormSubHeader" id="cancelFinalFormSubHeader">Please fill out the form below so we can process your cancellation as soon as possible</p>
                        <div className="reasonWrapper" id="cancelFinalFormReasonWrapper">
                            <p className="cancelReasonLabel" id="cancelReasonLabel">What was the reason for your cancellation?</p>
                            <select className="cancelReasonSelect defaultSelect required" id="cancelReasonSelect" name="cancelReasonSelect">
                                <option value="">Choose an option</option>
                                <option id="price" className="cancelReasonOption">Too expensive</option>
                                <option id="didnt_solve" className="cancelReasonOption">Didn't solve my problem</option>
                                <option id="features" className="cancelReasonOption">Not enough features</option>
                                <option id="popups" className="cancelReasonOption">Too many popups</option>
                                <option id="other" className="cancelReasonOption">Other</option>
                            </select>
                        </div>

                        <div id="cancelReasonTextWrapper" className="cancelReasonTextWrapper">
                            <p className="cancelReasonTextHeader" id="cancelReasonTextHeader">Was there something we could have done to keep you as a customer?</p>
                            <textarea id="cancelReasonText" className="cancelReasonText" />
                        </div>
                    </div>

                    <div className="cancelContinueChallenge appPanel defaultMaterial" id="cancelContinueChallenge">
                        <h2 className="cancelContinueChallengeHeader" id="cancelContinueChallengeHeader">Are you sure you want to cancel and lose these benefits?</h2>
                        <div className="cancelButtonArea" id="cancelButtonArea">
                            <input type="submit" className="appButton hollow endBenefitsButton cancelStepButton" id="endBenefitsButton" value="End My Benefits" />
                            {this.subscriptionIsDiscounted &&
                                <button type="button" className="appButton blue keepBenefitsButton cancelStepButton" id="keepBenefitsButton" onClick={this.onKeepBenefitsClicked}>Keep My Benefits</button>
                            }
                            {!this.subscriptionIsDiscounted &&
                                <button type="button" className="appButton blue keepBenefitsButton cancelStepButton discountButton" id="keepBenefitsButton" onClick={this.onKeepBenefitsClicked}>WAIT! I Want the Special Offer of {discountString}</button>
                            }
                        </div>
                    </div>
                </form>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering cancel Subscription ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportCriticalException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    public componentDidMount() {
        //prevent invalid subscription navigation and prevent navigation to this page when subscription is already cancelled
        let selectedSubscription = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions.find((sub: ISubscriptionViewModel) => sub.SubscriptionId == this.props.viewStore.activeSubscriptionId);
        if (!selectedSubscription || selectedSubscription.IsActive == false) {
            const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.Overview);
            this.props.dispatcher.DispatchAction(action);
        }

        $('#cancelFinalForm select').tooltipster({
            trigger: 'custom', // default is 'hover' which is no good here
            onlyOne: false, // allow multiple tips to be open at a time
            position: 'right', // display the tips to the right of the element
            theme: 'error-tooltip'
        });
        this.ValidationInit();
    }

    private onEndBenefitsClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.CANCEL_SUBSCRIPTION, null);
        this.props.dispatcher.DispatchAction(action);
    }

    private onKeepBenefitsClicked() {
        let action: IAction<AppActions>;
        if (!this.subscriptionIsDiscounted) {
            action = AppActionHelpers.CreateAction(AppActions.DISCOUNT_SUBSCRIPTION, null);
        }
        else {
            action = AppActionHelpers.CreateAction(AppActions.CANCEL_SUBSCRIPTION_BACK, null);
        }
        this.props.dispatcher.DispatchAction(action);
    }

    private ValidationInit() {
        $('#cancelFinalForm').validate({
            errorPlacement: function (error, element) {
                var lastError = $(element).data('lastError'),
                    newError = $(error).text();

                $(element).data('lastError', newError);

                if (newError !== '') {
                    $(element).tooltipster('content', newError);
                    $(element).tooltipster('open');
                }
            },
            rules: {
                cancelReasonSelect: "required"
            },
            submitHandler: () => {
                try {
                    if (this.props.viewStore.isUILocked) { return; }
                    this.SendCancelReason();
                } catch (ex) {
                    //TODO: if this fails we need to send an error
                }
            },
            success: function (label, element) {
                $(element).tooltipster('close');
            },
            messages: {
                cancelReasonSelect: "Please choose a cancellation reason"
            }
        });
    }

    private SendCancelReason() {

        const model = new CancelFeedbackModel();
        model.CancelReason = $(".cancelReasonSelect").val().toString();
        model.Email = this.props.viewStore.user.Email;
        model.Message = $(".cancelReasonText").val().toString();
        model.Name = `${this.props.viewStore.user.FirstName} ${this.props.viewStore.user.LastName}`;

        this.props.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.SUBMIT_CANCEL_FEEDBACK, model));
        this.props.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.CANCEL_SUBSCRIPTION, null));
    }
}
