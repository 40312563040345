import { Dot, WithStore } from 'pure-react-carousel';
import * as React from "react";



//export interface IDotGroupProps {
//    totalSlides: number;
//    currentSlide: number;
//    visibleSlides: number;
//    showAsSelectedForCurrentSlideOnly: boolean;
//    dotNumbers: boolean;
//    className: string;
//    children: any;
//}



class CustomDotGroup extends React.Component<any> {
    

    renderDots() {

        const totalSlides = this.props.totalSlides;
        const currentSlide = this.props.currentSlide;
        const visibleSlides = this.props.visibleSlides;

        const dots = [];
        for (let i = 0; i < totalSlides; i += 1) {
            const multipleSelected = i >= currentSlide && i < (currentSlide + visibleSlides);
            const singleSelected = i === currentSlide;
            //const selected = showAsSelectedForCurrentSlideOnly ? singleSelected : multipleSelected;
            const slide = i >= totalSlides - visibleSlides ? totalSlides - visibleSlides : i;
            dots.push(
                <Dot key={i}
                    slide={slide}
                    disabled={true}>
                    <span className={'carousel__dot-group-dot'}>{this.props.dotNumbers && i + 1}</span>
                </Dot>,
            );
        }
        return dots;
    }

    render() {
        

        const newClassName = `carousel__dot-group`;

        return (
            <div className={newClassName}>
                {this.renderDots()}
            </div>
        );
    }
};

export default WithStore(CustomDotGroup, state => ({
    currentSlide: state.currentSlide,
    totalSlides: state.totalSlides,
    visibleSlides: state.visibleSlides,
}));
