import { injectable, inject } from "inversify";
import { IViewStore } from "../../store/interfaces/IViewStore";
import { IUIApi } from "../../api/interfaces/IUIApi";
import { IExceptionReporter } from "../../api/interfaces/IExceptionReporter";
import { IDispatcher, ICallbackPayload } from "driversupport.frontend.common";
import { AppViews } from "../../core/enumerations/AppViews";
import { TYPES } from "../../core/enumerations/TYPES";
import { AppActions } from "../../core/enumerations/AppActions";
import { action, runInAction } from "mobx";
import { ErrorViewModel } from "../../core/classes/ErrorViewModel";
import { GoogleAnalytics } from "../../core/classes/Analytics";
import { AppActionHelpers, FormatHelpers } from "../../core/classes/Helpers";

declare var _kmq;

@injectable()
export class CancelSubscriptionObserver implements ICancelSubscriptionObserver {
    private readonly viewStore: IViewStore;
    private readonly uiApi: IUIApi;
    private readonly exceptionReporter: IExceptionReporter;
    private readonly dispatcher: IDispatcher<AppActions>;

    constructor(@inject(TYPES.ViewStore) viewStore: IViewStore,
        @inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter,
        @inject(TYPES.UIApi) uiApi: IUIApi,
        @inject(TYPES.Dispatcher) dispatcher: IDispatcher<AppActions>) {

        this.viewStore = viewStore;
        this.uiApi = uiApi;
        this.exceptionReporter = exceptionReporter;
        this.dispatcher = dispatcher;

        this.dispatcher.Register(this.dispatcherCallback.bind(this));
    }

    private dispatcherCallback(payload: ICallbackPayload<AppActions>): void {
        try {
            switch (payload.action.actionType) {

                case AppActions.CANCEL_SUBSCRIPTION:
                    this.handleCancelSubscription();
                    break;
                case AppActions.CANCEL_SUBSCRIPTION_BACK:
                    this.handleCancelSubscriptionBack();
                    break;
                case AppActions.CANCEL_SUBSCRIPTION_ADVANCE:
                    this.advanceCancelFlow();
                    break;
                case AppActions.DISCOUNT_SUBSCRIPTION:
                    this.handleSubscriptionDiscount();
                    break;
            }
        } catch (ex) {
            const error = new Error("Error processing app action in DispatcherCallback() in CancelSubscriptionObserver");
            this.exceptionReporter.ReportCriticalException(error);
        }
    }

    @action
    private async handleSubscriptionDiscount() {
        try {
            this.viewStore.isDiscountingSubscription = true;

            GoogleAnalytics.TrackEvent("UserPortal", "Cancel Subscription", "Take Discount");

            const response = await this.uiApi.discountSubscriptionAsync(this.viewStore.activeSubscriptionId, this.viewStore.user.RegistrationKey);

            runInAction(() => {
                if (response.isSuccessfulAction) {
                    this.viewStore.isDiscountingSubscription = false;
                    const action = AppActionHelpers.CreateAction(AppActions.REFRESH_UI, null);
                    this.dispatcher.DispatchAction(action);
                } else {
                    if (response.statusCode === 401) {
                        this.viewStore.isUILocked = false;
                        // if unauthorized just redirect to Login
                        this.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.LOGOFF, null));
                        return;
                    }
                    throw new Error(response.errorMessage);
                }
            });
        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            runInAction(() => {
                this.viewStore.cancelSubscriptionError = new ErrorViewModel("Error discounting subscription");
            });
        }
        finally {
            runInAction(() => {
                this.viewStore.isDiscountingSubscription = false;
            });
        }
    }

    @action
    private async handleCancelSubscription() {
        try {
            this.viewStore.isCancellingSubscription = true;

            const registerDate = new Date(this.viewStore.user.AccountDate);
            const now = new Date();
            let milliseconds = now.getTime() - registerDate.getTime();
            const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));
            milliseconds -= days * (1000 * 60 * 60 * 24);
            const hours = Math.floor(milliseconds / (1000 * 60 * 60));
            milliseconds -= hours * (1000 * 60 * 60);
            const minutes = Math.floor(milliseconds / (1000 * 60));
            const timeString = FormatHelpers.leftPadZeros(days, 2) + ":" + FormatHelpers.leftPadZeros(hours, 2) + ":" + FormatHelpers.leftPadZeros(minutes, 2);
            _kmq.push(['record', "Canceled"], { "Time Since Registration": timeString });

            GoogleAnalytics.TrackEvent("UserPortal", "Cancel Subscription", "Confirm Cancel");

            const response = await this.uiApi.cancelSubscriptionAsync(this.viewStore.activeSubscriptionId, this.viewStore.user.RegistrationKey);

            runInAction(() => {
                if (response.isSuccessfulAction) {
                    this.viewStore.isCancellingSubscription = false;
                    const action = AppActionHelpers.CreateAction(AppActions.REFRESH_UI, AppViews.SubscriptionDetail);
                    this.dispatcher.DispatchAction(action);
                } else {
                    if (response.statusCode === 401) {
                        this.viewStore.isUILocked = false;
                        // if unauthorized just redirect to Login
                        this.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.LOGOFF, null));
                        return;
                    }
                    throw new Error(response.errorMessage);
                }
            });
        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            runInAction(() => {
                this.viewStore.cancelSubscriptionError = new ErrorViewModel("Error cancelling subscription");
            });
        }
        finally {
            runInAction(() => {
                this.viewStore.isCancellingSubscription = false;
            });
        }
    }

    @action
    private handleCancelSubscriptionBack() {
        //reset cancel step
        this.viewStore.currentCancelStep = 1;
        this.viewStore.activeScreen = AppViews.PCSelector;
    }

    @action
    private advanceCancelFlow() {
        //increment our cancel step
        this.viewStore.currentCancelStep++;
        //get the next screen number from the dynamic cancel flow order array
        const screenNumber = this.viewStore.cancelFlowOrder[this.viewStore.currentCancelStep - 1];
        //get the screen by its number
        const screen = this.viewStore.cancelScreenArray[screenNumber - 1];
        //switch to screen
        this.viewStore.activeScreen = screen;
    }
}