import { IDriverSummaryMessage } from "../models/messages/IDriverSummaryMessage";
import { IMachineMessage } from "../models/messages/IMachineMessage";
import { ISoftwareOptimizationSummaryMessage } from "../models/messages/ISoftwareOptimizationSummaryMessage";
import { DriverSummaryViewModel } from "./DriverSummaryViewModel";
import { MachineViewModel } from "./MachineViewModel";
import { SoftwareOptimizationSummaryViewModel } from "./SoftwareOptimizationSummaryViewModel";

export class ViewModelBuilder {

    public static buildMachineVM(machine: IMachineMessage): MachineViewModel {
        let returnVM = new MachineViewModel();

        returnVM.coverageStartDate = new Date(machine.CoverageStartDate);
        //returnVM.cpu = machine.CPU;
        returnVM.driverSummary = ViewModelBuilder.buildDriverSummaryVM(machine.DriverSummary);
        returnVM.machineImageUrl = machine.MachineImageUrl;
        returnVM.machineName = machine.MachineName;
        returnVM.memorySize = machine.MemorySize;
        returnVM.monitoringStatus = machine.MonitoringStatus;
        returnVM.os = machine.OS;
        returnVM.softwareOptimizationSummary = ViewModelBuilder.buildSoftwareOptimizationSummaryVM(machine.SoftwareOptimizationSummary);
        returnVM.storageSize = machine.StorageSize;
        returnVM.licenseActivation = machine.LicenseActivation;

        return returnVM;
    }

    public static buildDriverSummaryVM(driverSummary: IDriverSummaryMessage) {
        let returnVM = new DriverSummaryViewModel();

        returnVM.nextScheduledScanDate = new Date(driverSummary.NextScheduledScan);
        returnVM.numDevicesMonitored = driverSummary.NumDevicesMonitored;
        returnVM.numDriverUpdatesAvailable = driverSummary.NumDriverUpdatesAvailable;
        returnVM.numDriveUpdatesRecent = driverSummary.NumDriverUpdatesRecent;

        return returnVM;
    }

    public static buildSoftwareOptimizationSummaryVM(softwareOptimizationSummary: ISoftwareOptimizationSummaryMessage) {
        let returnVM = new SoftwareOptimizationSummaryViewModel();

        returnVM.areOptimizationsActive = softwareOptimizationSummary.AreOptimizationsActive;
        returnVM.numBrowsingOptimizationsRecent = softwareOptimizationSummary.NumBrowsingOptimizationsRecent;
        returnVM.numDisruptiveProcessesSuspended = softwareOptimizationSummary.NumDisruptiveProcessesSuspended;
        returnVM.numGamingOptimizationsRecent = softwareOptimizationSummary.NumGamingOptimizationsRecent;
        returnVM.numProductivityOptimizationsRecent = softwareOptimizationSummary.NumProductivityOptimizationsRecent;
        returnVM.totalMemoryFreed = softwareOptimizationSummary.TotalMemoryFreed;

        return returnVM;
    }
}