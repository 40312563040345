import * as React from "react";
// @ts-ignore
import diagnose from "../../assets/img/computerDiagnose_large.png";
import { DriverSummaryViewModel } from "../viewModels/DriverSummaryViewModel";

export interface IServicePaneProps {
    vm: DriverSummaryViewModel;
}

export const ServicePane: React.StatelessComponent<IServicePaneProps> = (props: IServicePaneProps) => {
    return <div id="service_pane" className="service_pane overview_pane">
        <div id="header" className="header">
            <h2 id="headerText" className="headerText">Driver Update Service</h2>
        </div>

        <div id="contentContainer" className="contentContainer">
            <div id="updateSummary" className="updateSummary">
                <div id="update_updateEntry" className="updateEntry">
                    <div id="update_background" className="background past90Days">
                        <h3 id="update_valueText" className="valueText">{props.vm.displayUpdatesRecent}</h3>
                    </div>
                    <h3 id="update_entryText" className="entryText">Driver updates in the past 60 days</h3>
                </div>
                <div id="monitor_updateEntry" className="updateEntry">
                    <div id="monitor_background" className="background devicesMonitored">
                        <h3 id="monitor_valueText" className="valueText">{props.vm.displayDevicesMonitored}</h3>
                    </div>
                    <h3 id="monitor_entryText" className="entryText">Devices being monitored for updates</h3>
                </div>
                <div id="available_updateEntry" className="updateEntry">
                    <div id="available_background" className="background currentlyAvailable">
                        <h3 id="available_valueText" className="valueText">{props.vm.displayDriverUpdateAvailable}</h3>
                    </div>
                    <h3 id="available_entryText" className="entryText">Current available driver updates</h3>
                </div>
            </div>

            <div id="divider" className="divider" />

            <div id="scheduledScanContainer" className="scheduledScanContainer">

                <div id="scanIconContainer" className="scanIconContainer">
                    <img id="scanIcon" src={diagnose} />
                </div>

                <p id="scanText" className="scanText">We will continue to periodically scan your PC for new driver updates</p>
            </div>
        </div>
    </div>
};