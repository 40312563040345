import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../../core/classes/AppContext";
import { AppViews } from "../../core/enumerations/AppViews";
import { IComponentProps } from "../interfaces/IComponentProps";

export interface IMainMenuItemProps extends IComponentProps {
    isActive: boolean;
    text: string;
    mapToScreen: AppViews;
    onClick: () => void;
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter,
}))
@observer
export class MainMenuItem extends React.Component<Partial<IMainMenuItemProps>>{

    constructor(props) {
        super(props);
    }

    public render() {
        try {
            const text = this.props.text;
            const isActive = this.props.isActive;

            return <li className={"mainMenuItem" + (isActive ? ' active' : '')} onClick={this.props.onClick}>
                <p className="mainMenuItemText noselect">{text}</p>
            </li>
        } catch (ex) {
            let outerex = new Error(`Error Rendering Main Menu Item - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            return null;
        }
    }
}