import { inject } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { IComponentProps } from "../main/interfaces/IComponentProps";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
export class DriverBenefits extends React.Component<Partial<IComponentProps>>{

    public render() {

        const vm = this.props.viewStore.userPortalViewModel.SoftwareUsageViewModel;

        return <div className="driverBenefits">
            <h4 className="sectionTitle">Drivers</h4>
            <div className="driverSection">
                <div className="driverIcon" />
                <div className="driverSummaryBlock">
                    <p className="blockHeader">Driver Activity</p>
                    <p className="scansRun">{vm.ScansRun} scans run</p>
                    <p className="pnpUpdates">{vm.PNPUpdates} plug and play updates</p>
                    <p className="driversUpdated">{vm.DriversUpdated} drivers updated</p>
                </div>
                <div className="driverSummaryBlock">
                    <p className="blockHeader">Driver Issues</p>
                    <p className="outofDateDrivers">{vm.DriversOutOfDate} out of date drivers</p>
                    <p className="missingDrivers">{vm.DriversMissing} missing drivers</p>
                </div>
            </div>
        </div>;
    }
}