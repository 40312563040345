import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class PaymentDeclinedView extends React.Component<Partial<IComponentProps>>{

    constructor(props) {
        super(props);

        this.onUpdateClicked = this.onUpdateClicked.bind(this);
        this.onGoBackClicked = this.onGoBackClicked.bind(this);
    }

    public render() {
        return <div className="paymentDeclined appPanel defaultMaterial">
            <h1 className="panelTitle">Payment Method Declined</h1>
            <p className="paymentDeclinedText">Your payment method was declined.  Click 'Update Payment Method' below to enter a new payment method and retry resuming your subscription.</p>
            <div className="appButton blue paymentDeclinedUpdateButton" onClick={this.onUpdateClicked}>
                <p className="appButton__buttonText">Update Payment Method</p>
            </div>
            <button type="button" className="appButton grey paymentDeclinedBackButton" onClick={this.onGoBackClicked}>
                <p className="appButton__buttonText">Go Back</p>
            </button>
        </div>;
    }

    private onUpdateClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.UpdatePaymentAndResume);
        this.props.dispatcher.DispatchAction(action);
    }

    private onGoBackClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.SubscriptionDetail);
        this.props.dispatcher.DispatchAction(action);
    }
}