import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { GetCheckoutUrlPayload } from "../models/GetCheckoutUrlPayload";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class UpdatePaymentPP extends React.Component<Partial<IComponentProps>>{
    constructor(props) {
        super(props);

        this.onCancelClicked = this.onCancelClicked.bind(this);
        this.onContinueToPaypalClicked = this.onContinueToPaypalClicked.bind(this);
    }

    public render() {

        const error = this.props.viewStore.paypalError;
        const isLocked = this.props.viewStore.isUILocked;

        return <div id="updatePaymentMethodPPForm" className="updatePaymentMethodPPForm">
            <p id="paypalText" className="paypalText">To finish updating your payment method click the "Continue to PayPal" button and log in to PayPal using your email and password</p>

            <div id="ppForm_buttonContainer" className="ppForm_buttonContainer">
                <button id="continueToPaypalButton" type="submit" className="continueToPaypalButton appButton blue" onClick={this.onContinueToPaypalClicked}>
                    <p id="continueToPaypalButtonText" className="appButton__buttonText">Continue To PayPal</p>
                </button>
                <button id="pp_cancelButton" type="button" className="cancelButton appButton hollow" onClick={this.onCancelClicked.bind(this)}>
                    <p id="pp_cancelButtonText" className="appButton__buttonText">Cancel</p>
                </button>
            </div>
            {isLocked &&
                <div id="redirectingToPaypalLoader" className="redirectingToPaypalLoader miniLoader">
                    <div id="pp_loaderImage" className="loaderImage" />
                    <p id="redirectingToPaypalText" className="redirectingToPaypalText miniLoaderText">Redirecting to Paypal...</p>
                </div>
            }
            {error &&
                <div id="paypalErrorBox" className="paypalErrorBox miniErrorWrapper">
                    <div id="pp_errorImage" className="errorImage" />
                    <p id="paypalErrorText" className="paypalErrorText miniErrorText">{error.Message}</p>
                </div>
            }
        </div>;
    }

    private onCancelClicked() {
        if (this.props.viewStore.isUILocked) {
            return;
        }
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.SubscriptionDetail);
        this.props.dispatcher.DispatchAction(action);
    }

    private onContinueToPaypalClicked() {

        if (this.props.viewStore.isUILocked) {
            return;
        }

        const payload = new GetCheckoutUrlPayload();
        payload.registrationKey = this.props.viewStore.user.RegistrationKey;
        payload.returnAction = this.props.viewStore.activeScreen === AppViews.UpdatePaymentAndResume ? "Resume" : "Update";
        payload.subscriptionId = this.props.viewStore.activeSubscriptionId;

        const action = AppActionHelpers.CreateAction(AppActions.GET_EXPRESS_CHECKOUT_URL, payload);
        this.props.dispatcher.DispatchAction(action);
    }
}