import { CreditCardTypes } from "../../models/CreditCardTypes";
import { ICreditCardViewModel } from "../../models/ICreditCardViewModel";
import { PaymentTypes } from "../../models/PaymentTypes";

export class CreditCardUtils {

    public static GetIconClassFromPaymentMethodType(payment: ICreditCardViewModel, paymentType: PaymentTypes): string {

        if (paymentType == PaymentTypes.Paypal) {
            return "paypal";
        }

        switch (payment.CardType) {
            case CreditCardTypes.Visa:
                return "visa";
            case CreditCardTypes.MasterCard:
                return "masterCard";
            case CreditCardTypes.Discover:
                return "discover";
            case CreditCardTypes.Amex:
                return "amex";
            default:
                return null;
        }
    }

    public static GetExpirationMonth(payment: ICreditCardViewModel): number {
        let re = /(.*)\/(.*)/;
        let arr = re.exec(payment.ExpirationDate);
        if (arr.length > 0)
            return Number(arr[1]);
        else
            return 0;
    }

    public static GetExpirationYear(payment: ICreditCardViewModel): number {
        let re = /(.*)\/(.*)/;
        let arr = re.exec(payment.ExpirationDate);
        if (arr.length > 0)
            return Number(arr[2]);
        else
            return 0;
    }

    public static GetCardTypeString(payment: ICreditCardViewModel) {
        switch (payment.CardType) {
            case CreditCardTypes.Visa:
                return "Visa";
            case CreditCardTypes.MasterCard:
                return "MasterCard";
            case CreditCardTypes.Discover:
                return "Discover";
            case CreditCardTypes.Amex:
                return "American Express";
            default:
                return null;
        }
    }
}