import { FormatHelpers } from "../core/classes/Helpers";

export class SoftwareOptimizationSummaryViewModel {
    numBrowsingOptimizationsRecent: number;
    numProductivityOptimizationsRecent: number;
    numGamingOptimizationsRecent: number;
    areOptimizationsActive: boolean;
    totalMemoryFreed: number;
    numDisruptiveProcessesSuspended: number;

    get displayBrowsingOptimizations(): string {
        return FormatHelpers.convertNumToDisplay(this.numBrowsingOptimizationsRecent);
    }

    get displayProductivityOptimizations(): string {
        return FormatHelpers.convertNumToDisplay(this.numProductivityOptimizationsRecent);
    }

    get displayGamingOptimizations(): string {
        return FormatHelpers.convertNumToDisplay(this.numGamingOptimizationsRecent);
    }

    get displayMemoryFreed(): string {
        return FormatHelpers.convertBytesToMB(this.totalMemoryFreed).toString();
    }

    get displayDisruptiveProcesses(): string {
        return this.numDisruptiveProcessesSuspended.toString();
    }
}