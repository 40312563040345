import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class MessageSentForm extends React.Component<Partial<IComponentProps>>{

    constructor(props) {
        super(props);

        this.onBackClicked = this.onBackClicked.bind(this);
    }

    public render() {
        return <div className="wrapper contactFormWrapper">
            <div className="appPanel messageSent defaultMaterial contactForm">
                <h2 className="panelTitle">Message Sent</h2>
                <p className="messageSentText">Your message has been sent.</p>
                <button type="button" className="backButton appButton blue" onClick={this.onBackClicked}>
                    <p className="appButton__buttonText">Go Back</p>
                </button>
            </div>
        </div>;
    }

    private onBackClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.Support);
        this.props.dispatcher.DispatchAction(action);
    }
}