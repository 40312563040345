export interface ICustomerBillingData {
    Address: string;
    City: string;
    Country: string;
    Email: string;
    FirstName: string;
    LastName: string;
    State: string;
    Zip: string;
    PhoneNumber: string;
}

export class CustomerBillingData implements ICustomerBillingData {
    public Address: string;
    public City: string;
    public Country: string;
    public Email: string;
    public FirstName: string;
    public LastName: string;
    public State: string;
    public Zip: string;
    public PhoneNumber: string;
}