import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { IComponentProps } from "../main/interfaces/IComponentProps";

export interface ISubscriptionStatusProps extends IComponentProps {
    showNextCharge: boolean;
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class SubscriptionStatus extends React.Component<Partial<ISubscriptionStatusProps>, undefined>{

    public render() {
        try {

            const selectedSubscription = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions.find(s => s.SubscriptionId === this.props.viewStore.activeSubscriptionId);
            const price = selectedSubscription.SubscriptionPrice
            const isActive = selectedSubscription.IsActive;
            const nextBillDate = selectedSubscription.NextBillingDate;
            const status = selectedSubscription.SubscriptionStatus;

            let renderComponent = null;
            let statusIconClass = this.getStatusIconClass();
            if (isActive) {
                renderComponent = <div className="subscriptionStatusWrapper">
                    <div className="subscriptionStatusIcon active" />
                    <p className="subscriptionStatusText">
                        Your current subscription is <span className="activeText">ACTIVE</span>.<br />
                    </p>
                    {this.props.showNextCharge &&
                        <p className="nextChargeWrapper">
                            Your next charge of ${price} will be billed on {nextBillDate}
                        </p>
                    }
                </div>;
            }
            else {
                renderComponent = <div className="subscriptionStatusWrapper">
                    <div className={`subscriptionStatusIcon ${statusIconClass}`} />
                    <p className="subscriptionStatusText">
                        Your current subscription is <span className="inactiveText">{status.toUpperCase()}</span>.<br />
                    </p>
                </div>;
            }

            return renderComponent;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering SubscriptionStatus - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private getStatusIconClass(): string {
        let statusIconClass = "";
        switch (status.toLowerCase()) {
            case ("active"):
                return "active";
            case ("cancelled"):
                return "inactive";
            case ("suspended"):
                return "suspended";
        }
    }
}