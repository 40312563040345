import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { CreditCardUtils } from "../core/classes/CreditCardUtils";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { CreditCardTypes } from "../models/CreditCardTypes";
import { PaymentTypes } from "../models/PaymentTypes";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class PaymentMethodArea extends React.Component<Partial<IComponentProps>, undefined>{
    public render() {
        try {
            const payment = this.props.viewStore.userPortalViewModel.AccountViewModel.Payment;
            const paymentType = this.props.viewStore.userPortalViewModel.AccountViewModel.PaymentType;
            const paymentMethodClassName = CreditCardUtils.GetIconClassFromPaymentMethodType(payment, paymentType);

            return <div className="paymentMethodArea">
                <h2 className="panelSubTitle">Payment Method</h2>
                <div className="floatedContentWrapper">
                    <div className={`paymentTypeIcon ${paymentMethodClassName}`} />
                    <p className="paymentInfo">
                        {(paymentType === PaymentTypes.CreditCard) && `${CreditCardTypes[payment.CardType].toString()} ending in ${payment.LastFourOfCard}`}
                        {(paymentType === PaymentTypes.Paypal) && "Paypal"}
                    </p>
                </div>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering PaymentMethodArea - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }
}