import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { OverviewView } from "./OverviewView";
import { SubscriptionsAndBillingView } from "./SubscriptionsAndBillingView";
import { UpdatePersonalInformationView } from "./UpdatePersonalInformationView";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class SubscriptionView extends React.Component<Partial<IComponentProps>, undefined> {

    constructor(props) {
        super(props);
    }

    public render() {
        try {
            return <div className="subscriptionPages">
                {this.GetUIDisplay()}
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering SubscriptionView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private GetUIDisplay() {
        switch (this.props.viewStore.activeScreen) {
            case AppViews.Overview:
                return <OverviewView />;
            case AppViews.UpdateUserInfo:
                return <UpdatePersonalInformationView />;
            case AppViews.SubscriptionDetail:
            case AppViews.UpdatePayment:
            case AppViews.SelectPayment:
            case AppViews.CancelSubscription:
            case AppViews.CancelSubscription2:
            case AppViews.CancelSubscription3:
            case AppViews.CancelSubscription4:
            case AppViews.CancelSubscription5:
            case AppViews.UpdatePaymentAndResume:
            case AppViews.PreCancel:
            case AppViews.ResolvThankYou:
            case AppViews.ViewInvoices:
            case AppViews.PaymentDeclined:
                return <SubscriptionsAndBillingView />
        }
    }
}