import { inject, injectable } from "inversify";
import { IDataGetResponse, IDataPostResponse } from "../../core/classes/Responses";
import { TYPES } from "../../core/enumerations/TYPES";
import { CreditCardData } from "../../models/CreditCardData";
import { CustomerBillingData } from "../../models/CustomerBillingData";
import { ICheckoutUrlResponse } from "../../models/ICheckoutUrlResponse";
import { ILicenseActivationResponse } from "../../models/ILicenseActivationResponse";
import { IPaymentActionStatus } from "../../models/IPaymentActionStatus";
import { ISubscriptionActionStatus } from "../../models/ISubscriptionActionStatus";
import { IUserPortalViewModel } from "../../models/IUserPortalViewModel";
import { IUserPortalViewModelResponse } from "../../models/IUserPortalViewModelResponse";
import { ILicenseActivationMessage } from "../../models/messages/ILicenseActivationMessage";
import { IExceptionReporter } from "../interfaces/IExceptionReporter";
import { IUIApi } from "../interfaces/IUIApi";

declare var uiControllerPath;

@injectable()
export class UIApi implements IUIApi {


    private exceptionReporter: IExceptionReporter

    constructor(@inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter) {

        this.exceptionReporter = exceptionReporter;
    }

    public getUserPortalViewModelAsync(zuoraAccountId: string, registrationKey: string): Promise<IUserPortalViewModelResponse> {

        const path = `${uiControllerPath}GetUserPortalViewModel?zuoraAccountId=${zuoraAccountId}&registrationKey=${registrationKey}`;

        return fetch(path)
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataGetResponse
                return {
                    base: {} as IUserPortalViewModelResponse,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    userPortalVM: ro.Success ? JSON.parse(ro.Payload) as IUserPortalViewModel : null,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during GetUserPortalViewModel");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to GetUserPortalViewModel failed with status: ${statusCode}`), e);
                return {
                    base: {} as IUserPortalViewModelResponse,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                    userPortalVM: null
                }
            });
    }

    public cancelSubscriptionAsync(subscriptionId: string, registrationKey: string): Promise<ISubscriptionActionStatus> {
        const payload = JSON.stringify({ subscriptionId: subscriptionId, registrationKey: registrationKey });
        const ajaxPath = `${uiControllerPath}CancelSubscription`;

        return fetch(ajaxPath, {
            method: "POST",
            body: payload,
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataPostResponse;
                return {
                    base: {} as ISubscriptionActionStatus,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during CancelSubscription");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to CancelSubscription failed with status: ${statusCode}`), e);
                return {
                    base: {} as ISubscriptionActionStatus,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode
                }
            });
    }

    public discountSubscriptionAsync(subscriptionId: string, registrationKey: string): Promise<ISubscriptionActionStatus> {
        const payload = JSON.stringify({ subscriptionId: subscriptionId, registrationKey: registrationKey });
        const ajaxPath = `${uiControllerPath}DiscountSubscription`

        return fetch(ajaxPath, {
            method: "POST",
            body: payload,
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataPostResponse;
                return {
                    base: {} as ISubscriptionActionStatus,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during DiscountSubscription");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to DiscountSubscription failed with status: ${statusCode}`), e);
                return {
                    base: {} as ISubscriptionActionStatus,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode
                }
            });
    }

    public resumeSubscriptionAsync(subscriptionId: string, registrationKey: string, uuid: string): Promise<ISubscriptionActionStatus> {
        const payload = JSON.stringify({ subscriptionId: subscriptionId, registrationKey: registrationKey, uuid: uuid });
        const ajaxPath = `${uiControllerPath}ResumeSubscription`;

        return fetch(ajaxPath, {
            method: "POST",
            body: payload,
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataPostResponse;
                return {
                    base: {} as ISubscriptionActionStatus,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during ResumeSubscription");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to ResumeSubscription failed with status: ${statusCode}`), e);
                return {
                    base: {} as ISubscriptionActionStatus,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode
                }
            });
    }

    public resumeSubscriptionPaymentDeclinedAsync(registrationKey: string): Promise<ISubscriptionActionStatus> {
        const payload = JSON.stringify({ registrationKey: registrationKey });
        const ajaxPath = `${uiControllerPath}ResumeSubscriptionPaymentDeclined`;

        return fetch(ajaxPath, {
            method: "POST",
            body: payload,
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataPostResponse;
                return {
                    base: {} as ISubscriptionActionStatus,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during ResumeSubscriptionPaymentDeclined");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to ResumeSubscriptionPaymentDeclined failed with status: ${statusCode}`), e);
                return {
                    base: {} as ISubscriptionActionStatus,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode
                }
            });
    }

    public updatePaymentMethodAsync(accountKey: string, creditCard: CreditCardData, customerBilling: CustomerBillingData): Promise<IPaymentActionStatus> {
        const payload = JSON.stringify({ accountKey: accountKey, creditCard: creditCard, customerBilling: customerBilling });
        const ajaxPath = `${uiControllerPath}UpdatePaymentMethod`;

        return fetch(ajaxPath, {
            method: "POST",
            body: payload,
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataPostResponse;
                return {
                    base: {} as IPaymentActionStatus,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during UpdatePaymentMethod");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to UpdatePaymentMethod failed with status: ${statusCode}`), e);
                return {
                    base: {} as ISubscriptionActionStatus,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                }
            });
    }

    public getExpressCheckoutUrlAsync(subscriptionId: string, registrationKey: string, returnAction: string): Promise<ICheckoutUrlResponse> {

        const path = `${uiControllerPath}ExpressCheckoutUrl?subscriptionId=${subscriptionId}&registrationKey=${registrationKey}&returnAction=${returnAction}`;

        return fetch(path)
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataGetResponse
                return {
                    base: {} as ICheckoutUrlResponse,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    checkoutUrl: ro.Success ? ro.Payload as string : null,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during ExpressCheckoutUrl");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to ExpressCheckoutUrl failed with status: ${statusCode}`), e);
                return {
                    base: {} as ISubscriptionActionStatus,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                    checkoutUrl: null
                }
            });
    }

    public updateLicenseActivationAsync(licenseActivation: ILicenseActivationMessage): Promise<ILicenseActivationResponse> {
        const payload = JSON.stringify({ licenseActivation: licenseActivation });
        const ajaxPath = `${uiControllerPath}UpdateLicenseActivation`;

        return fetch(ajaxPath, {
            method: "POST",
            body: payload,
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataGetResponse;
                return {
                    base: {} as ILicenseActivationResponse,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    license: ro.Success ? JSON.parse(ro.Payload) as ILicenseActivationMessage : null,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during UpdateLicenseActivation");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to UpdateLicenseActivation failed with status: ${statusCode}`), e);
                return {
                    base: {} as ISubscriptionActionStatus,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                    license: null
                }
            });
    }

    private validateResponse(response: Response) {
        if (!response.ok) {
            throw Error(response.status.toString());
        }
        return response;
    }
}