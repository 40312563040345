import { inject, injectable } from "inversify";
import { IDataPostResponse } from "../../core/classes/Responses";
import { TYPES } from "../../core/enumerations/TYPES";
import { CancelFeedbackModel } from "../../models/CancelFeedbackModel";
import { ContactModel } from "../../models/ContactModel";
import { IContactResponse } from "../../models/IContactResponse";
import { IExceptionReporter } from "../interfaces/IExceptionReporter";
import { ISupportApi } from "../interfaces/ISupportApi";

declare var supportControllerPath;

@injectable()
export class SupportApi implements ISupportApi {

    private exceptionReporter: IExceptionReporter

    constructor(@inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter) {

        this.exceptionReporter = exceptionReporter;
    }

    public submitContactRequest(payload: ContactModel): Promise<IContactResponse> {

        const ajaxPath = `${supportControllerPath}Contact`;

        return fetch(ajaxPath, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataPostResponse;
                return {
                    base: {} as IContactResponse,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during Contact");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to Contact failed with status: ${statusCode}`), e);
                return {
                    base: {} as IContactResponse,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                }
            });
    }

    public submitCancelFeedback(payload: CancelFeedbackModel): Promise<IContactResponse> {

        const ajaxPath = `${supportControllerPath}CancelFeedback`;

        return fetch(ajaxPath, {
            method: "POST",
            body: JSON.stringify(payload),
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataPostResponse;
                return {
                    base: {} as IContactResponse,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during CancelFeedback");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to CancelFeedback failed with status: ${statusCode}`), e);
                return {
                    base: {} as IContactResponse,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                }
            });
    }

    private validateResponse(response: Response) {
        if (!response.ok) {
            throw Error(response.status.toString());
        }
        return response;
    }
}