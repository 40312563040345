import * as React from "react";
// @ts-ignore
import background from "../../assets/img/purchaseBanner.png";

declare var cartUrl: string;

export interface PurchaseBannerProps {
    upgradeCB: any;
}

export const PurchaseBanner: React.StatelessComponent<PurchaseBannerProps> = (props) => {
    return <div id="purchaseBanner" className="purchaseBanner">
        <img src={background} className="purchaseBannerBG" id="purchaseBannerBG" />
        <div className="purchaseBannerContentArea" id="purchaseBannerContentArea">
            <h2 className="purchaseBannerHeader" id="purchaseBannerHeader">Keep your Solve iQ benefits up and running</h2>
            <h4 className="purchaseBannerSubHeader" id="purchaseBannerSubHeader">Continue getting the performance and protection you need</h4>
            <button type="button" id="purchaseBannerButton" className="purchaseBannerButton appButton green" onClick={props.upgradeCB}>
                <p className="appButton__buttonText">Upgrade Your Plan</p>
            </button>
        </div>
    </div>
};