import { AppViews } from "../enumerations/AppViews";

export class ViewUtils {

    public static isSupportScreen(screen: AppViews) {
        return screen === AppViews.QuestionAboutBill ||
            screen === AppViews.SoftwareInstallProblem ||
            screen === AppViews.RegistrationKeyForm ||
            screen === AppViews.DriverQuestionForm ||
            screen === AppViews.InstallDriverProblem ||
            screen === AppViews.RequestARefundForm ||
            screen === AppViews.MessageSent ||
            screen === AppViews.ContactForm ||
            screen === AppViews.Support;
    }

    public static isSubscriptionAndBillingScreen(screen: AppViews) {
        return screen === AppViews.SubscriptionDetail ||
            screen === AppViews.UpdatePayment ||
            screen === AppViews.UpdatePaymentAndResume ||
            screen === AppViews.CancelSubscription ||
            screen === AppViews.SelectPayment ||
            screen === AppViews.PreCancel ||
            screen === AppViews.ResolvThankYou ||
            screen === AppViews.ViewInvoices ||
            screen === AppViews.PaymentDeclined;
    }

    public static doesScreenRequireLogin(activeScreen: AppViews) {
        return (activeScreen === AppViews.Overview ||
            activeScreen === AppViews.SubscriptionDetail ||
            activeScreen === AppViews.PCSelector ||
            activeScreen === AppViews.UpdatePayment);
    }
    public static isScreenHistoryNavigatable(activeScreen: AppViews) {
        return !(activeScreen === AppViews.SelectPayment ||
            activeScreen === AppViews.CancelSubscription ||
            activeScreen === AppViews.UpdatePaymentAndResume);
    }

}