import { observable, runInAction, action } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { CanadianProvince } from "../input/components/CanadianProvince";
import { CountryCodeSelector } from "../input/components/CountryCodeSelector";
import { StateDropDown } from "../input/components/StateDropDown";
import { StateInputField } from "../input/components/StateInputField";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { CustomerBillingData } from "../models/CustomerBillingData";

export enum PaymentCountries {
    USA,
    Canada,
    Other
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class BillingAddressForm extends React.Component<Partial<IComponentProps>>{

    @observable selectedCountryType: PaymentCountries;
    @observable selectedState: string;
    @observable selectedFirstName: string;
    @observable selectedLastName: string;
    @observable selectedAddress: string;
    @observable selectedCity: string;
    @observable selectedZip: string;
    @observable selectedCountry: string;

    constructor(props) {
        super(props);

        this.calculateCountryType = this.calculateCountryType.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleZipChange = this.handleZipChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);

        this.handleAddressChange(props.viewStore.user.Address);
        this.handleCityChange(props.viewStore.user.City);
        this.handleCountryChange(props.viewStore.user.Country);
        this.handleFirstNameChange(props.viewStore.user.FirstName);
        this.handleLastNameChange(props.viewStore.user.LastName);
        this.handleStateChange(props.viewStore.user.State);
        this.handleZipChange(props.viewStore.user.Zip);
    }

    public componentDidUpdate() {
        //since the state input is mutable we need to attach tooltipster manually when we update the component
        this.AttachStateTooltip();
    }

    public render() {

        const isLocked = this.props.viewStore.isUILocked;
        const user = this.props.viewStore.user;

        let opts: Object = {};
        if (this.props.viewStore.isUILocked) {
            opts['disabled'] = 'disabled';
        }

        return <div id="billingAddressForm" className="billingAddressForm">
            <p id="ba_firstNameLabel" className="cardFormLabel">First Name</p>
            <input {...opts} id="ba_firstNameInput" type="text" name="FirstName" className="cardFormInput billingInput firstNameInput" defaultValue={user.FirstName} />
            
            <p id="ba_lastNameLabel" className="cardFormLabel">Last Name</p>
            <input {...opts} id="ba_lastNameInput" type="text" name="LastName" className="cardFormInput billingInput lastNameInput" defaultValue={user.LastName} />
            
            <p id="ba_addressLabel" className="cardFormLabel">Address</p>
            <input {...opts} id="ba_addressInput" type="text" name="Address" className="cardFormInput billingInput addressInput" defaultValue={user.Address} />
            
            <p id="ba_cityLabel" className="cardFormLabel">City</p>
            <input {...opts} id="ba_cityInput" type="text" name="City" className="cardFormInput billingInput cityInput" defaultValue={user.City} />
            
            <p id="ba_stateLabel" className="cardFormLabel">State/Region</p>

            {this.selectedCountryType === PaymentCountries.Other &&
                <StateInputField
                    opts={opts}
                    currentState={this.selectedState}
                    onChange={(s) => this.handleStateChange(s)}
                />
            }
            {this.selectedCountryType === PaymentCountries.USA &&
                <StateDropDown
                    opts={opts}
                    currentState={this.selectedState}
                    onChange={(s) => this.handleStateChange(s)}
                />
            }
            {this.selectedCountryType === PaymentCountries.Canada &&
                <CanadianProvince
                    opts={opts}
                    currentState={this.selectedState}
                    onChange={(s) => this.handleStateChange(s)}
                />
            }
            
            <p id="ba_zipLabel" className="cardFormLabel">Zip/Postal Code</p>
            <input {...opts} id="ba_zipInput" type="text" name="ZipCode" className="cardFormInput billingInput zipInput" defaultValue={user.Zip} />
            
            <p id="ba_countryLabel" className="cardFormLabel">Country</p>
            <CountryCodeSelector isDisabled={isLocked}
                onChange={(c) => this.handleCountryChange(c)}
                currentCountry={this.selectedCountry} />
            
        </div>;
    }

    public LoadBillingInfo(): CustomerBillingData {
        var retBilling = new CustomerBillingData();
        retBilling.FirstName = $('.firstNameInput').val().toString();
        retBilling.LastName = $('.lastNameInput').val().toString();
        retBilling.Address = $('.addressInput').val().toString();
        retBilling.City = $('.cityInput').val().toString();
        retBilling.State = $('.stateInput').val().toString();
        retBilling.Zip = $('.zipInput').val().toString();
        retBilling.Country = $('.countryInput').val().toString();

        return retBilling;
    }

    private AttachStateTooltip() {
        $('#State').tooltipster({
            trigger: 'custom', // default is 'hover' which is no good here
            onlyOne: false, // allow multiple tips to be open at a time
            position: 'right', // display the tips to the right of the element
            theme: 'error-tooltip'
        });
    }

    private isValidUSState(stateCode: string) {
        if (stateCode == null) {
            return false;
        }

        const testCode = stateCode.toUpperCase();

        switch (testCode) {
            case "AK":
            case "AZ":
            case "AR":
            case "CA":
            case "CO":
            case "CT":
            case "DE":
            case "DC":
            case "FL":
            case "GA":
            case "HI":
            case "ID":
            case "IL":
            case "IN":
            case "IA":
            case "KS":
            case "KY":
            case "LA":
            case "ME":
            case "MD":
            case "MA":
            case "MI":
            case "MN":
            case "MS":
            case "MO":
            case "MT":
            case "NE":
            case "NV":
            case "NH":
            case "NJ":
            case "NM":
            case "NY":
            case "NC":
            case "ND":
            case "OH":
            case "OK":
            case "OR":
            case "PA":
            case "RI":
            case "SC":
            case "SD":
            case "TN":
            case "TX":
            case "UT":
            case "VT":
            case "VA":
            case "WA":
            case "WV":
            case "WI":
            case "WY":
                return true;
            default:
                return false;
        }
    }

    private isValidCanadianProvince(stateCode: string) {

        if (stateCode == null) {
            return false;
        }

        const testCode = stateCode.toUpperCase();

        switch (testCode) {
            case "AB":
            case "BC":
            case "MB":
            case "NB":
            case "NL":
            case "NS":
            case "ON":
            case "PE":
            case "QC":
            case "SK":
            case "NT":
            case "NU":
            case "YT":
                return true;
            default:
                return false;
        }
    }

    // actions to handle internal state
    // these do not modify global mobx store
    @action
    private handleFirstNameChange(firstName: string) {
        this.selectedFirstName = firstName;
    }
    @action
    private handleLastNameChange(lastName: string) {
        this.selectedLastName = lastName;
    }
    @action
    private handleAddressChange(address: string) {
        this.selectedAddress = address;
    }
    @action
    private handleCityChange(city: string) {
        this.selectedCity = city;
    }
    @action
    private handleStateChange(state: string) {
        this.selectedState = state;
    }
    @action
    private handleZipChange(zip: string) {
        this.selectedZip = zip;
    }
    @action
    private handleCountryChange(country: string) {
        this.selectedCountry = country;
        this.calculateCountryType(country);

        switch (this.selectedCountryType) {
            case PaymentCountries.USA:
                this.handleUSCountrySelected();
                break;
            case PaymentCountries.Canada:
                this.handleCanadaCountrySelected();
                break;
            default:
                this.handleOtherCountrySelected();
                break;
        }
    }

    @action
    private handleUSCountrySelected() {
        if (this.isValidUSState(this.selectedState)) {
            return;
        }

        // not a valid state -- try to fallback to the original value
        if (this.isValidUSState(this.props.viewStore.user.State)) {
            this.selectedState = this.props.viewStore.user.State;
            return;
        }

        // set to blank to allow the user to pick a state
        this.selectedState = "";
    }

    @action
    private handleCanadaCountrySelected() {
        if (this.isValidCanadianProvince(this.selectedState)) {
            return;
        }

        // not a valid state -- try to fallback to the original value
        if (this.isValidCanadianProvince(this.props.viewStore.user.State)) {
            this.selectedState = this.props.viewStore.user.State;
            return;
        }

        // set to blank to allow the user to pick a state
        this.selectedState = "";
    }

    @action
    private handleOtherCountrySelected() {
        this.selectedState = "";
    }

    @action
    private calculateCountryType(countryValue: string) {

        if (countryValue === "US" && this.selectedCountryType != PaymentCountries.USA) {
            this.selectedCountryType = PaymentCountries.USA;
            return;
        }

        if (countryValue === "CA" && this.selectedCountryType != PaymentCountries.Canada) {
            this.selectedCountryType = PaymentCountries.Canada;
            return;
        }

        //check to make sure we aren't already in other state.  don't need to update if that's the case
        if (this.selectedCountryType !== PaymentCountries.Other) {
            this.selectedCountryType = PaymentCountries.Other;
        }
    }
}