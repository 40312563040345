import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { PaymentMethodArea } from "../payment/PaymentMethodArea";
import { RegistrationKeyArea } from "./RegistrationKeyArea";
import { SubscriptionPlan } from "./SubscriptionPlan";
import { SubscriptionSelect } from "./SubscriptionSelect";
import { SubscriptionStatus } from "./SubscriptionStatus";
// @ts-ignore
import editIcon from "../../assets/img/icn-edit-cc.png";
// @ts-ignore
import subIcon from "../../assets/img/icn-subscription.png";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class SubscriptionDetailView extends React.Component<Partial<IComponentProps>, undefined>{
    constructor(props) {
        super(props);

        this.onActivateSubscriptionClicked = this.onActivateSubscriptionClicked.bind(this);
        this.onCancelSubscriptionClicked = this.onCancelSubscriptionClicked.bind(this);
        this.onUpdatePaymentClicked = this.onUpdatePaymentClicked.bind(this);
        this.onViewInvoicesClicked = this.onViewInvoicesClicked.bind(this);
    }

    public render() {
        try {

            if (this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions.length === 0) {
                //we dont have any subscriptions, nothing to do here just return.
                return;
            }
            const selectedSubscription = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions.find(s => s.SubscriptionId === this.props.viewStore.activeSubscriptionId);
            const isSelectedSubscriptionRefunded = selectedSubscription.SubscriptionStatus.toLowerCase() === "refunded";

            return <div className="yourSubscriptionPanel detailed appPanel defaultMaterial">
                <div className="detailSection">
                    <h2 className="panelTitle">
                        <img src={subIcon} className="subscriptionIcon" />
                        Your Subscription</h2>
                    <div className="spacer" />
                    <button type="button" className="viewInvoicesLink linkButton" onClick={this.onViewInvoicesClicked}>View Invoices</button>
                </div>

                <div className="detailSection">
                    <SubscriptionSelect />

                    <SubscriptionStatus showNextCharge={true} />
                </div>

                <div className="detailSection">
                    <SubscriptionPlan />
                </div>

                <div className="detailSection">
                    <PaymentMethodArea />
                    <div className="spacer" />
                    {selectedSubscription.IsActive &&
                        <button type="button" className="updatePaymentButtonText linkButton" onClick={this.onUpdatePaymentClicked}>
                            <img className="updatePaymentIcon" src={editIcon} />
                            Update Payment Method
                        </button>
                    }
                </div>

                <div className="detailSection">
                    <RegistrationKeyArea />
                </div>

                <div className="detailSection">
                    <div className="spacer" />
                    {selectedSubscription.IsActive &&
                        <button type="button" className="cancelSubscriptionLink linkButton" onClick={this.onCancelSubscriptionClicked}>Cancel Subscription</button>
                    }

                    {!selectedSubscription.IsActive &&
                        <button type="button" className="activateSubscriptionButton appButton blue" onClick={() => this.onActivateSubscriptionClicked(isSelectedSubscriptionRefunded)}>
                            <p className="activateSubscriptionLink appButton__buttonText">Resume Service</p>
                        </button>
                    }
                </div>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering SubscriptionDetailView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private onActivateSubscriptionClicked(isRefunded: boolean) {
        if (isRefunded) {
            this.props.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.LAUNCH_CART, null));
        } else {
            this.props.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.SelectPayment));
        }
    }

    private onCancelSubscriptionClicked() {
        this.props.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.CancelSubscription));
    }

    private onViewInvoicesClicked() {
        this.props.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.ViewInvoices));
    }

    private onUpdatePaymentClicked() {
        this.props.dispatcher.DispatchAction(AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.UpdatePayment));
    }
}