import * as React from "react";
// @ts-ignore
import folderIcon from "../../assets/img/folder_outline.png";
// @ts-ignore
import memoryIcon from "../../assets/img/memory_outline.png";
// @ts-ignore
import monitorDisabledIcon from "../../assets/img/monitoringDisabled.png";
// @ts-ignore
import monitorIcon from "../../assets/img/monitoringIcon.gif";
import { SoftwareOptimizationSummaryViewModel } from "../viewModels/SoftwareOptimizationSummaryViewModel";
import { OptimizationSummary } from "./OptimizationSummary";

export interface IOptimizationPaneProps {
    vm: SoftwareOptimizationSummaryViewModel;
}

export const OptimizationPane: React.StatelessComponent<IOptimizationPaneProps> = (props: IOptimizationPaneProps) => {

    const isActiveText = props.vm.areOptimizationsActive ? "active" : "inactive";

    return <div id="optimization_pane" className="optimization_pane overview_pane ">
        <div id="header" className="header">
            <h2 id="headerText" className="headerText">Software Optimization Service</h2>
        </div>

        <div id="contentContainer" className="contentContainer">
            <OptimizationSummary vm={props.vm} />

            <div id="divider" className="divider" />

            <div id="monitorContainer" className="monitorContainer">
                {props.vm.areOptimizationsActive &&
                    <img id="monitorIconDisabled" className="monitorIcon" src={monitorIcon} />
                }
                {!props.vm.areOptimizationsActive &&
                    <img id="monitorIcon" className="monitorIcon disabled" src={monitorDisabledIcon} />
                }
                <h3 id="monitoringText" className="monitoringText">{`Software optimizations are ${isActiveText}`}</h3>

                <div id="memoryContainer" className="memoryContainer">
                    <img id="memoryIcon" className="memoryIcon" src={memoryIcon} />
                    <div id="memoryValueContainer" className="memoryValueContainer">
                        <h2 id="memoryValue" className="memoryValue">{props.vm.displayMemoryFreed}</h2>
                        <h3 id="memoryDescription" className="memoryDescription">MB Memory Freed</h3>
                    </div>
                </div>

                <div id="disruptiveProcessContainer" className="disruptiveProcessContainer">
                    <img id="disruptiveProcessIcon" className="disruptiveProcessIcon" src={folderIcon} />
                    <div id="disruptiveProcessValueContainer" className="disruptiveProcessValueContainer">
                        <h2 id="disruptiveProcessValue" className="disruptiveProcessValue">{props.vm.displayDisruptiveProcesses}</h2>
                        <h3 id="disruptiveProcessDescription" className="disruptiveProcessDescription">Disruptive Processes Suspended</h3>
                    </div>
                </div>

            </div>
        </div>

    </div>
};