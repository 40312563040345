import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { CreditCardUtils } from "../core/classes/CreditCardUtils";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { CreditCardTypes } from "../models/CreditCardTypes";
import { PaymentTypes } from "../models/PaymentTypes";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class SelectPaymentView extends React.Component<Partial<IComponentProps>>{

    constructor(props) {
        super(props);

        this.onCancelClicked = this.onCancelClicked.bind(this);
        this.onEnterNewPaymentClicked = this.onEnterNewPaymentClicked.bind(this);
        this.onUserCardOnFileClicked = this.onUserCardOnFileClicked.bind(this);
    }

    public render() {
        try {
            const payment = this.props.viewStore.userPortalViewModel.AccountViewModel.Payment;
            const paymentType = this.props.viewStore.userPortalViewModel.AccountViewModel.PaymentType;
            const paymentClass = CreditCardUtils.GetIconClassFromPaymentMethodType(payment, paymentType)

            return <div className="selectPayment appPanel defaultMaterial">
                <h2 className="panelTitle">Select Payment Method</h2>
                <div className="paymentMethodBox">
                    <div className={`cardLogo paymentTypeIcon ${paymentClass}`}></div>
                    <p className="paymentInfo">
                        {(paymentType == PaymentTypes.CreditCard) && `${CreditCardTypes[payment.CardType].toString()} ending in ${payment.LastFourOfCard}`}
                        {(paymentType == PaymentTypes.Paypal) && "Paypal"}
                    </p>
                    <button type="button" className="enterNewPaymentButton linkButton" onClick={this.onEnterNewPaymentClicked}>Enter New Payment</button>
                </div>
                <button type="button" className="cancelButton appButton grey" onClick={this.onCancelClicked}>
                    <p className="appButton__buttonText">Cancel</p>
                </button>
                <button type="button" className="useThisPaymentLink appButton blue" onClick={this.onUserCardOnFileClicked}>
                    <p className="appButton__buttonText">Use Card On File</p>
                </button>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering SelectPaymentView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private onCancelClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.SubscriptionDetail);
        this.props.dispatcher.DispatchAction(action);
    }

    private onEnterNewPaymentClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.UpdatePaymentAndResume);
        this.props.dispatcher.DispatchAction(action);
    }

    private onUserCardOnFileClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.RESUME_SUBSCRIPTION, this.props.viewStore.activeSubscriptionId);
        this.props.dispatcher.DispatchAction(action);
    }
}