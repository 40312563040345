import { ICallbackPayload, IDispatcher, Page } from "driversupport.frontend.common";
import { inject, injectable, postConstruct } from "inversify";
import { action, runInAction } from "mobx";
import { IAuthApi } from "../../api/interfaces/IAuthApi";
import { IExceptionReporter } from "../../api/interfaces/IExceptionReporter";
import { IUIApi } from "../../api/interfaces/IUIApi";
import { ErrorViewModel } from "../../core/classes/ErrorViewModel";
import { AppActions } from "../../core/enumerations/AppActions";
import { AppViews } from "../../core/enumerations/AppViews";
import { TYPES } from "../../core/enumerations/TYPES";
import { PasswordPayload } from "../../models/PasswordPayload";
import { IViewStore } from "../../store/interfaces/IViewStore";
import { IResetPasswordObserver } from "../interfaces/IResetPasswordObserver";

declare var uiControllerPath: string;

@injectable()
export class ResetPasswordObserver implements IResetPasswordObserver {

    private readonly viewStore: IViewStore;
    private readonly authApi: IAuthApi;
    private readonly uiApi: IUIApi;
    private readonly exceptionReporter: IExceptionReporter;
    private readonly dispatcher: IDispatcher<AppViews>;

    constructor(@inject(TYPES.ViewStore) viewStore: IViewStore,
        @inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter,
        @inject(TYPES.AuthApi) authApi: IAuthApi,
        @inject(TYPES.UIApi) uiApi: IUIApi,
        @inject(TYPES.Dispatcher) dispatcher: IDispatcher<AppViews>) {

        this.viewStore = viewStore;
        this.authApi = authApi;
        this.uiApi = uiApi;
        this.exceptionReporter = exceptionReporter;
        this.dispatcher = dispatcher;

        this.dispatcher.Register(this.dispatcherCallback.bind(this));
    }

    // this is called when inversify creates an instance of this class
    @postConstruct()
    @action
    public async init() {
        try {
            this.viewStore.activeScreen = AppViews.ResetPassword;
            this.viewStore.isLoggedIn = false;
        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            runInAction(() => {
                this.viewStore.isError = true;
            });
        }
    }

    private dispatcherCallback(payload: ICallbackPayload<AppActions>): void {
        try {
            switch (payload.action.actionType) {

                case AppActions.RESET_PASSWORD:
                    this.handleResetPassword(payload.action.data as string);
                    break;
            }
        } catch (ex) {
            const error = new Error("Error processing app action in DispatcherCallback() in ResetPasswordObserver");
            this.exceptionReporter.ReportCriticalException(error);
        }
    }

    @action
    private async handleResetPassword(newPassword: string) {
        try {
            const userToken = Page.GetParameterByName("userToken");
            const payload = new PasswordPayload();
            payload.userToken = userToken;
            payload.password = newPassword;

            this.viewStore.isUILocked = true;

            const response = await this.authApi.setNewPassword(payload);

            if (response.isSuccessfulAction) {
                location.href = `${uiControllerPath}`;
            } else {
                throw new Error(response.errorMessage);
            }
        }
        catch (e) {
            this.exceptionReporter.ReportException(e);
            runInAction(() => {
                this.viewStore.resetPasswordError = new ErrorViewModel("Error setting new password please try again.");
            });
        }
        finally {
            runInAction(() => {
                this.viewStore.isUILocked = false;
            });
        }
    }
}
