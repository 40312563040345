import { inject, injectable } from "inversify";
import { action, computed, observable } from "mobx";
import { IAuthApi } from "../../api/interfaces/IAuthApi";
import { IExceptionReporter } from "../../api/interfaces/IExceptionReporter";
import { AppViews, ForgotPasswordViews, OverviewViews } from "../../core/enumerations/AppViews";
import { LoadActions } from "../../core/enumerations/LoadActions";
import { TYPES } from "../../core/enumerations/TYPES";
import { IErrorViewModel } from "../../core/interfacaes/IErrorViewModel";
import { IUserPortalViewModel } from "../../models/IUserPortalViewModel";
import { IDhqUser } from "../../models/User";
import { MachineViewModel } from "../../viewModels/MachineViewModel";
import { IViewStore } from "../interfaces/IViewStore";
import { ISubscriptionViewModel } from "../../models/ISubscriptionViewModel";

declare var cancelFlowOrderOverride: any;

@injectable()
export class ViewStore implements IViewStore {
    @observable isUILocked: boolean;
    @observable isLoggedIn: boolean;
    @observable isLoggingIn: boolean;
    @observable user: IDhqUser
    @observable isInitialized: boolean;

    @observable activeScreen: AppViews;
    @observable forgotPasswordView: ForgotPasswordViews;
    @observable activeOverviewView: OverviewViews;

    @observable returnScreen: AppViews;
    @observable initialScreen: AppViews;
    @observable loadAction: LoadActions;
    @observable userPortalViewModel: IUserPortalViewModel;
    @observable activeSubscriptionId: string;
    locale: string;
    @observable isError: boolean;
    @observable isInErrorState: boolean;
    @observable updatePersonalInfoError: IErrorViewModel;
    @observable updatePaymentError: IErrorViewModel;
    @observable paypalError: IErrorViewModel;
    @observable loginError: IErrorViewModel
    @observable resetPasswordError: IErrorViewModel;
    @observable forgotPasswordError: IErrorViewModel;
    @observable contactError: IErrorViewModel;

    @observable forgotPasswordEmail: string;
    @observable sentPasswordEmail: string;


    @observable localHttpServerPort: string;
    @observable uuidQstring: string;

    @observable cancelSubscriptionError: IErrorViewModel;
    @observable isDiscountingSubscription: boolean;
    @observable isCancellingSubscription: boolean;
    @observable cancelFlowOrder: number[];
    @observable currentCancelStep: number;

    //@observable currentMachine: MachineViewModel;
    @observable currentMachineIndex: number;
    @observable allMachines: MachineViewModel[];

    cancelScreenArray: AppViews[];
    private exceptionReporter: IExceptionReporter;
    private authApi: IAuthApi;


    constructor(@inject(TYPES.AuthApi) authApi: IAuthApi,
        @inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter) {

        this.authApi = authApi;
        this.exceptionReporter = exceptionReporter;

        this.InitStore();
    }

    @action
    private InitStore() {
        this.allMachines = [];
        this.currentMachineIndex = null;
        this.currentCancelStep = 1;
        this.cancelScreenArray = [AppViews.CancelSubscription,
        AppViews.CancelSubscription2,
        AppViews.CancelSubscription3,
        AppViews.CancelSubscription4,
        AppViews.CancelSubscription5];

        //if we have an override for testing set it if not use default test
        if (cancelFlowOrderOverride != null) {
            this.cancelFlowOrder = cancelFlowOrderOverride;
        }
        else {
            this.cancelFlowOrder = [1, 2, 3, 4, 5];
        }
    }

    @computed
    public get activeSubscription(): ISubscriptionViewModel {
        return this.userPortalViewModel.AccountViewModel.Subscriptions.find(sub => sub.SubscriptionId == this.activeSubscriptionId);
    }

    @computed
    public get agentPort() {
        return (this.localHttpServerPort !== "") ? this.localHttpServerPort : "65411";
    }

    @computed
    public get userFullName() {
        return `${this.user.FirstName} ${this.user.LastName}`;
    }

    @computed
    public get enabledMachines() {
        return this.allMachines.filter(m => m.licenseActivation.IsEnabled);
    }
}
