export enum AppViews {
    Overview = 1,
    SubscriptionDetail = 2,
    Support = 3,
    Login = 4,
    Loading = 5,
    RegistrationKeyForm = 7,
    DriverQuestionForm = 8,
    RequestARefundForm = 9,
    MessageSent = 10,
    QuestionAboutBill = 11,
    InstallDriverProblem = 12,
    SoftwareInstallProblem = 13,
    ContactForm = 14,
    UpdatePayment = 15,
    UpdatePaymentAndResume = 16,
    SelectPayment = 17,
    CancelSubscription = 18,
    PreCancel = 19,
    ResolvThankYou = 20,
    ViewInvoices = 21,
    PaymentDeclined = 22,
    ResetPassword = 23,
    ForgotPassword = 24,
    KnowledgeBase = 25,
    UpdateUserInfo = 26,
    PCSelector = 27,
    CancelSubscription2 = 28,
    CancelSubscription3 = 29,
    CancelSubscription4 = 30,
    CancelSubscription5 = 31
}

export enum OverviewViews {
    Zuora,
    Standard,
    UpdateInfo
}

export enum ForgotPasswordViews {
    InputEmail,
    EmailSent
}

