import * as React from "react";
// @ts-ignore
import browsing from "../../assets/img/browsingBackground.png";
// @ts-ignore
import gaming from "../../assets/img/gamingBackground.png";
// @ts-ignore
import productivity from "../../assets/img/productivityBackground.png";
import { SoftwareOptimizationSummaryViewModel } from "../viewModels/SoftwareOptimizationSummaryViewModel";

export interface IOptimizationSummaryProps {
    vm: SoftwareOptimizationSummaryViewModel;
}

export const OptimizationSummary: React.StatelessComponent<IOptimizationSummaryProps> = (props: IOptimizationSummaryProps) => {
    return <div className="optimizationSummary">

        <div className="optimizationEntry">
            <div className="backgroundContainer">
                <img className="backgroundIcon" src={browsing} />
                <h3 className="valueText">{props.vm.displayBrowsingOptimizations}</h3>
            </div>

            <div className="textContainer">
                <h3 className="entryHeaderText">Browsing</h3>
                <div className="spacerContainer">
                    <h3 className="entryText">We've improved your browsing <span className="entryValue">{props.vm.displayBrowsingOptimizations}</span> times in the last 60 days</h3>
                    <div className="spacer" />
                </div>
            </div>
        </div>

        <div className="optimizationEntry">
            <div className="backgroundContainer">
                <img className="backgroundIcon" src={productivity} />
                <h3 className="valueText">{props.vm.displayProductivityOptimizations}</h3>
            </div>
            <div className="textContainer">
                <h3 className="entryHeaderText">Productivity</h3>
                <h3 className="entryText">We've increased your productivity <span className="entryValue">{props.vm.displayProductivityOptimizations}</span> times in the last 60 days</h3>
            </div>
        </div>

        <div className="optimizationEntry">
            <div className="backgroundContainer">
                <img className="backgroundIcon" src={gaming} />
                <h3 className="valueText">{props.vm.displayGamingOptimizations}</h3>
            </div>
            <div className="textContainer">
                <h3 className="entryHeaderText">Gaming</h3>
                <div className="spacerContainer">
                    <h3 className="entryText">We've optimized your gaming <span className="entryValue">{props.vm.displayGamingOptimizations}</span> times in the last 60 days</h3>
                    <div className="spacer" />
                </div>
            </div>
        </div>
    </div>
}