import { captureException, captureMessage, withScope } from "@sentry/core";
import { Severity } from "@sentry/types";
import { injectable } from "inversify";
import { IExceptionReporter } from "../interfaces/IExceptionReporter";

@injectable()
export class ExceptionReporter implements IExceptionReporter {

    ReportException(error: Error, extraInfo?: object) {
        withScope(scope => {
            if (extraInfo) {
                scope.setExtras(extraInfo);
            }
            scope.setLevel(Severity.Error);
            captureException(error);
        });
    }

    ReportCriticalException(error: Error, extraInfo?:object) {
        withScope(scope => {
            if (extraInfo) {
                scope.setExtras(extraInfo);
            }
            scope.setLevel(Severity.Critical);
            captureException(error);
        });
    }

    LogMessage(message: string) {
        captureMessage(message);
    }
}