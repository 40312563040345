import * as React from "react";
import { IStateInputProps } from "../interfaces/IStateInputProps";

export class StateInputField extends React.Component<IStateInputProps>
{
    public render() {
        const opts = this.props.opts;
        return <input {...opts} id="State" type="text" name="State" className="cardFormInput billingInput stateInput"
            value={this.props.currentState} onChange={(e) => this.props.onChange(e.target.value)} />;
    }
}