export interface ICreditCardData {
    CardNumber: string;
    ExpirationMonth: string;
    ExpirationYear: string;
    SecurityCode: string;
    Type: string;
}

export class CreditCardData implements ICreditCardData {
    public CardNumber: string;
    public ExpirationMonth: string;
    public ExpirationYear: string;
    public SecurityCode: string;
    public Type: string;
}