import * as React from "react";
// @ts-ignore
import xIcon from "../../assets/img/xIcon_dark.png";
// @ts-ignore
import oopsModalBacker from "../../assets/img/oopsModalBacker.svg";

interface IOopsMobileModalProps {
    closeCB: () => void;
}

const OopsMobileModal: React.FC<IOopsMobileModalProps> = (props: IOopsMobileModalProps) => {
    return <div className="modalContainer" id="oopsModalContainer">
        <div className="oopsModal" id="oopsModal">
            <img id="closeButton" className="closeButton" src={xIcon} onClick={props.closeCB} />
            <h1 className="oopsModalHeaderText" id="oopsModalHeaderText">
                Oops!
            </h1>
            <h3 className="oopsModalSubHeaderText" id="oopsModalSubHeaderText">
                Please make sure you are currently on your Windows PC in order to download
            </h3>
            <img className="oopsModalImage" id="oopsModalImage" src={oopsModalBacker} />
        </div>
    </div>
};

export default OopsMobileModal;