import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { BillingHistoryView } from "../payment/BillingHistoryView";
import { PaymentDeclinedView } from "../payment/PaymentDeclinedView";
import { SelectPaymentView } from "../payment/SelectPaymentView";
import { UpdatePaymentView } from "../payment/UpdatePaymentView";
import { SubscriptionDetailView } from "./SubscriptionDetailView";
import { CancelSubscription } from "../cancelsubscription/CancelSubscription";
import { CancelSubscription2 } from "../cancelsubscription/CancelSubscription2";
import { CancelSubscription3 } from "../cancelsubscription/CancelSubscription3";
import { CancelSubscription4 } from "../cancelsubscription/CancelSubscription4";
import { CancelSubscription5 } from "../cancelsubscription/CancelSubscription5";
import { UpdatePersonalInformationView } from "./UpdatePersonalInformationView";
import { PersonalInformationView } from "./PersonalInformationView";
import { PurchaseBanner } from "./PurchaseBanner";
import buildUrl from 'build-url';

declare var cartUrl: string;

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class SubscriptionsAndBillingView extends React.Component<Partial<IComponentProps>> {

    constructor(props) {
        super(props);

        this.onUpgradeSubscriptionClicked = this.onUpgradeSubscriptionClicked.bind(this);
    }

    public render() {
        try {
            const subscriptions = (this.props.viewStore.userPortalViewModel.AccountViewModel != null) ? this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions : null;
            const hasSubscription = subscriptions != null && subscriptions.length > 0;

            return <div className="subscriptionsAndBilling">
                <div className="subscriptionsAndBillingWrapper">
                    {!hasSubscription && <PurchaseBanner upgradeCB={this.onUpgradeSubscriptionClicked}/>}
                    {this.GetUIDisplay()}
                </div>
            </div>;

        }
        catch (ex) {
            let outerex = new Error(`Error Rendering SubscriptionsAndBillingView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private GetUIDisplay(): JSX.Element {

        const activeScreen = this.props.viewStore.activeScreen;

        switch (activeScreen) {
            case AppViews.SubscriptionDetail:
                return <div>
                    <SubscriptionDetailView />
                    <PersonalInformationView />
                </div>;
            case AppViews.UpdatePayment:
                return <UpdatePaymentView />;
            case AppViews.UpdatePaymentAndResume:
                return <UpdatePaymentView />;
            case AppViews.SelectPayment:
                return <SelectPaymentView />;
            case AppViews.ViewInvoices:
                return <BillingHistoryView />;
            case AppViews.PaymentDeclined:
                return <PaymentDeclinedView />;
            case AppViews.CancelSubscription:
                return <CancelSubscription />;
            case AppViews.CancelSubscription2:
                return <CancelSubscription2 />;
            case AppViews.CancelSubscription3:
                return <CancelSubscription3 />;
            case AppViews.CancelSubscription4:
                return <CancelSubscription4 />;
            case AppViews.CancelSubscription5:
                return <CancelSubscription5 />;
        }
    }


    private onUpgradeSubscriptionClicked() {
        window.location.href = buildUrl(cartUrl, {
            queryParams: {
                registrationKey: this.props.viewStore.user.RegistrationKey,
                channel: 'solveiq',
                productID: '4'
            }
        });
    }
}
