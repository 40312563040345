import * as React from "react";
import { IStateInputProps } from "../interfaces/IStateInputProps";

export class CanadianProvince extends React.Component<IStateInputProps>
{
    public render() {
        const opts = this.props.opts;
        return <select {...opts} id="State" name="State" className="cardFormInput billingInput stateInput"
            value={this.props.currentState}
            onChange={(e) => this.props.onChange(e.target.value)} >
            <option value="">Please select your province</option>
            <option value="AB">Alberta</option>
            <option value="BC">British Columbia</option>
            <option value="MB">Manitoba</option>
            <option value="NB">New Brunswick</option>
            <option value="NL">Newfoundland and Labrador</option>
            <option value="NS">Nova Scotia</option>
            <option value="ON">Ontario</option>
            <option value="PE">Prince Edward Island</option>
            <option value="QC">Quebec</option>
            <option value="SK">Saskatchewan</option>
            <option value="NT">Northwest Territories</option>
            <option value="NU">Nunavut</option>
            <option value="YT">Yukon</option>
        </select>;
    }
}