import { IDispatcher } from "driversupport.frontend.common";
import { Container } from "inversify";
import { IAuthApi } from "../../api/interfaces/IAuthApi";
import { IExceptionReporter } from "../../api/interfaces/IExceptionReporter";
import { IObserverController } from "../../observers/interfaces/IObserverController";
import { IStoreController } from "../../store/interfaces/IStoreController";
import { AppActions } from "../enumerations/AppActions";
import { TYPES } from "../enumerations/TYPES";
import { IUIApi } from "../../api/interfaces/IUIApi";

export interface IProviderWrapper {
    appContext: IAppContext;
}

export interface IAppContext {
    Store: IStoreController;
    Dispatcher: IDispatcher<AppActions>;
    ExceptionReporter: IExceptionReporter;
    DIContainer: Container;
    Observer: IObserverController;
    AuthApi: IAuthApi;
    UIApi: IUIApi;
}

export class AppContext implements IAppContext {
    public Dispatcher: IDispatcher<AppActions>;
    public ExceptionReporter: IExceptionReporter;
    public Store: IStoreController;
    public Observer: IObserverController;
    public AuthApi: IAuthApi;
    public UIApi: IUIApi;
    public DIContainer: Container;

    constructor(container: Container) {
        this.Dispatcher = container.get<IDispatcher<AppActions>>(TYPES.Dispatcher);
        this.ExceptionReporter = container.get<IExceptionReporter>(TYPES.ExceptionReporter);
        this.Store = container.get<IStoreController>(TYPES.StoreController);
        this.Observer = container.get<IObserverController>(TYPES.ObserverController);
        this.AuthApi = container.get<IAuthApi>(TYPES.AuthApi);
        this.UIApi = container.get<IUIApi>(TYPES.UIApi);
        this.DIContainer = container;
    }
}