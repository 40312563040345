import { inject, observer } from "mobx-react";
import * as React from "react";
// @ts-ignore
import warningIcon from "../../../assets/img/warningIcon.png";
// @ts-ignore
import xIcon from "../../../assets/img/xIcon_dark.png";
import { IComponentProps } from "../../main/interfaces/IComponentProps";
import { IProviderWrapper } from "../classes/AppContext";
import { AppActionHelpers } from "../classes/Helpers";
import { AppActions } from "../enumerations/AppActions";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class GenericErrorView extends React.Component<Partial<IComponentProps>, undefined>{
    constructor(props) {
        super(props);

        this.onCloseClicked = this.onCloseClicked.bind(this);
    }

    public render() {
        try {
            return <div className="genericErrorView" id="genericErrorView">
                <div className="workingModalBacker" id="workingModalBacker" />
                <div className="workingModal" id="workingModal">
                    <img className="warningIcon" id="warningIcon" src={warningIcon} />
                    <p className="workingText" id="workingModalText">An error occurred. Please try again.</p>
                    <img className="closeButton" id="closeButton" src={xIcon} onClick={this.onCloseClicked} />
                </div>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering SubscriptionDetailView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private onCloseClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.CLOSE_ERROR_MODAL, null);
        this.props.dispatcher.DispatchAction(action);
    }
}