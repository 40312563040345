import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { IComponentProps } from "../main/interfaces/IComponentProps";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class SubscriptionPlan extends React.Component<Partial<IComponentProps>, undefined>
{
    public render() {
        try {

            const selectedSubscription = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions.find(s => s.SubscriptionId === this.props.viewStore.activeSubscriptionId);
            let productClass = this.GetProductClassFromSubscriptionPackage(selectedSubscription.SubscriptionPackage);

            return <div className="SubscriptionPlan">
                <h2 className="panelSubTitle">Subscription Plan</h2>
                <div className="subscriptionPlanWrapper floatedContentWrapper">
                    <div className={`subscriptionIcon ${productClass}`} />
                    <p className="subscriptionPlan">{`${selectedSubscription.SubscriptionPackage} - ${selectedSubscription.SubscriptionPrice}`}</p>
                </div>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering SubscriptionPlan - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private GetProductClassFromSubscriptionPackage(subPackage: string): string {
        var lowerPackage = subPackage.toLowerCase();
        if (lowerPackage.startsWith("solve iq") || lowerPackage.startsWith("solveiq")) {
            return "solveiq";
        }

        switch (subPackage) {
            case "Driver Support with Active Optimization":
                return "ds";
            case "Driver Support One":
                return "dso";
            default:
                return null;
        }
    }
}