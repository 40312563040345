import { FormatHelpers } from "../core/classes/Helpers";

export class DriverSummaryViewModel {
    numDriveUpdatesRecent: number;
    numDevicesMonitored: number;
    numDriverUpdatesAvailable: number;
    nextScheduledScanDate: Date;
    
    get displayUpdatesRecent(): string {
        return FormatHelpers.convertNumToDisplay(this.numDriveUpdatesRecent);
    }

    get displayDevicesMonitored(): string {
        return FormatHelpers.convertNumToDisplay(this.numDevicesMonitored);
    }

    get displayDriverUpdateAvailable(): string {
        return FormatHelpers.convertNumToDisplay(this.numDriverUpdatesAvailable);
    }

    get displayNextScheduledScanDate(): string {
        return FormatHelpers.converDateToDisplay(this.nextScheduledScanDate);
    }
}