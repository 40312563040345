import { inject } from "mobx-react";
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from "pure-react-carousel";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { MachineViewModel } from "../viewModels/MachineViewModel";
import CustomDotGroup from "./CustomDotGroup";
import { PCSelectorItemView } from "./PCSelectorView";
// @ts-ignore
import rightArrow from "../../assets/img/rightArrow.png";
// @ts-ignore
import leftArrow from "../../assets/img/leftArrow.png";

interface IPCCarouselProps extends IComponentProps {
    carouselData: MachineViewModel[];
    onPCSelected: (machine: MachineViewModel) => void;
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter,
}))
export class PCCarousel extends React.Component<Partial<IPCCarouselProps>, undefined>{


    constructor(props) {
        super(props);
    }

    public render() {

        try {
            return <div id="pcCarousel" className="pcCarousel">
                <CarouselProvider
                    naturalSlideWidth={220}
                    naturalSlideHeight={220}
                    visibleSlides={3}
                    disableKeyboard={true}
                    dragEnabled={false}
                    touchEnabled={false}
                    totalSlides={this.props.carouselData.length}>

                    <Slider>
                        {this.props.carouselData.map((machine, index) => {
                            return <Slide key={index} index={index} innerClassName="defaultPcImage">
                                <PCSelectorItemView key={index} machine={machine} addAnotherPc={false} onClickHandler={() => this.props.onPCSelected(machine)} />
                            </Slide>
                        })}
                    </Slider>
                    <ButtonBack className="carouselButtonHost">
                        <div>
                            <img className="backButton" src={leftArrow} />
                        </div>
                    </ButtonBack>
                    <ButtonNext className="carouselButtonHost">
                        <div>
                            <img className="nextButton" src={rightArrow}/>
                        </div>
                    </ButtonNext>
                    <CustomDotGroup />
                </CarouselProvider>
            </div>

        } catch (ex) {
            let outerex = new Error(`Error Rendering PCSelectorView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            return null;
        }
    }
};