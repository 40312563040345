import { action, observable, runInAction } from "mobx";
import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { PaymentTypes } from "../models/PaymentTypes";
import { UpdatePaymentCC } from "./UpdatePaymentCC";
import { UpdatePaymentPP } from "./UpdatePaymentPP";
// @ts-ignore
import arrow from "../../assets/img/icon-dropdown.png";
// @ts-ignore
import cc_all from "../../assets/img/cc/cc_all.png";
// @ts-ignore
import pp from "../../assets/img/cc/paypal_large.png";


enum UpdatePaymentForms {
    None,
    CCForm,
    PPForm
}

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class UpdatePaymentView extends React.Component<Partial<IComponentProps>>
{

    @observable paymentView;

    constructor(props) {
        super(props);
        runInAction(() => {
            if (this.props.viewStore.userPortalViewModel.AccountViewModel.PaymentType === PaymentTypes.Paypal) {
                this.paymentView = UpdatePaymentForms.PPForm;
            } else {
                this.paymentView = UpdatePaymentForms.CCForm;
            }
        });

        this.ShowCCForm = this.ShowCCForm.bind(this);
        this.ShowPPForm = this.ShowPPForm.bind(this);
    }

    public render() {

        const ccHeaderClassName = this.paymentView === UpdatePaymentForms.CCForm ? "expanded" : "";
        const ppHeaderClassName = this.paymentView === UpdatePaymentForms.PPForm ? "expanded" : "";

        try {
            return <div id="updatePayment_root" className="updatePayment">
                <div id="updatePayment_CC" className="updatePaymentCC">
                    <div id="updatePaymentCC_header" className={`updatePaymentFormHeaderBox ${ccHeaderClassName}`} onClick={this.ShowCCForm}>
                        <img id="updatePaymentCC_arrow" className={`paymentFormArrow ${ccHeaderClassName}`} src={arrow} />
                        <p id="updatePaymentCC_paymentTypeBoxText" className="paymentTypeBoxText">Credit or Debit Card</p>
                        <img id="ccPaymentTypes_image" className="paymentTypesBoxCardTypesImage" src={cc_all} />
                    </div>
                    {this.paymentView === UpdatePaymentForms.CCForm &&
                        <UpdatePaymentCC />
                    }
                </div>

                <div id="updatePayment_pp" className="updatePPBox">
                    <div id="updatePaymentPP_header" className={`updatePaymentFormHeaderBox ${ppHeaderClassName}`} onClick={this.ShowPPForm}>
                        <img id="updatePaymentPP_arrow" className={`paymentFormArrow ${ppHeaderClassName}`} src={arrow} />
                        <img id="updatePaymentPP_paymentTypes" className="paymentTypesBoxPaypalImage" src={pp} />
                    </div>
                    {this.paymentView === UpdatePaymentForms.PPForm &&
                        <UpdatePaymentPP />
                    }
                </div>
            </div>;
        } catch (ex) {
            let outerex = new Error(`Error Rendering UpdatePaymentView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    @action
    private ShowCCForm() {
        if (this.props.viewStore.isUILocked) {
            return;
        }

        if (this.paymentView === UpdatePaymentForms.CCForm) {
            this.paymentView = UpdatePaymentForms.None;
        } else {
            this.paymentView = UpdatePaymentForms.CCForm;
        }
    }

    @action
    private ShowPPForm() {
        if (this.props.viewStore.isUILocked) {
            return;
        }

        if (this.paymentView === UpdatePaymentForms.PPForm) {
            this.paymentView = UpdatePaymentForms.None;
        } else {
            this.paymentView = UpdatePaymentForms.PPForm;
        }
    }
}