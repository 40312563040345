import { inject, observer } from "mobx-react";
import * as React from "react";
import { GoogleAnalytics } from "../core/classes/Analytics";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { AOBenefits } from "./AOBenefits";
import { ContactForm } from "./ContactForm";
import { DriverBenefits } from "./DriverBenefits";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class RefundChallengeView extends React.Component<Partial<IComponentProps>>{

    constructor(props) {
        super(props);

        this.onContactClicked = this.onContactClicked.bind(this);
        this.onGoBackClicked = this.onGoBackClicked.bind(this);
    }

    public render() {
        try {
            const hasScan = this.props.viewStore.userPortalViewModel.SoftwareUsageViewModel.HasScan;
            const hasVeloxum = this.props.viewStore.userPortalViewModel.SoftwareUsageViewModel.HasVeloxum;

            return <div className="cancelSubscription refund appPanel defaultMaterial">
                <h2 className="panelTitle">Requesting a Refund</h2>
                <p className="cancelSubscriptionText">
                    We’re sorry that you feel that DriverSupport hasn’t performed up to your expectations. Before you request a refund we would
                love to <span className="contactLink" onClick={this.onContactClicked.bind(this)}>hear from you</span>. There are many steps that our representatives can take to address any problems you may be experiencing. if
                                            you would still like to request a refund please use the form at the bottom of this page.
            </p>
                <p className="cancelSubscriptionText">
                    For your reference here is a list of the benefits you have received from DriverSupport.
            </p>
                {hasScan &&
                    <DriverBenefits />
                }
                    
                {hasVeloxum &&
                    <AOBenefits />
                }
                <ContactForm topic={"Refund Request"} />
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering RefuncChallenge Screen - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private onGoBackClicked() {
        GoogleAnalytics.TrackEvent("AccountPortal", "Refund", "Go Back Clicked");
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.Support);
        this.props.dispatcher.DispatchAction(action);
    }

    private onContactClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.ContactForm);
        this.props.dispatcher.DispatchAction(action);
    }
}