declare var ga: any;

export class GoogleTMAnalytics {
    public static TrackEvent(eventCategory: string, eventAction: string, eventLabel: string) {
        ga('gtm1.send', 'event', {
            'eventCategory': eventCategory,
            'eventAction': eventAction,
            'eventLabel': eventLabel
        });
    }

    public static TrackVirtualPageView(page: string) {
        ga('gtm1.send', {
            hitType: 'pageview',
            page: page
        })
    }
}

export class GoogleAnalytics {
    public static TrackEvent(eventCategory: string, eventAction: string, eventLabel: string) {
        ga('send', 'event', {
            'eventCategory': eventCategory,
            'eventAction': eventAction,
            'eventLabel': eventLabel
        });
    }

    public static TrackVirtualPageView(page: string) {
        ga('send', {
            hitType: 'pageview',
            page: page
        })
    }
}

