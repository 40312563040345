export enum TYPES {
    ViewStore = "ViewStore",
    Dispatcher = "Dispatcher",
    ExceptionReporter = "ExceptionReporter",
    AuthApi = "AuthApi",
    UIApi = "UIApi",
    SupportApi = "SupportApi",
    ObserverController = "ObserverController",
    StoreController = "StoreController",
    ViewObserver = "ViewObserver",
    ForgotPasswordObserver = "ForgotPasswordObserver",
    ResetPasswordObserver = "ResetPasswordObserver",
    KnowledgeBaseObserver = "KnowledgeBaseObserver",
    CancelSubscriptionObserver = "CancelSubscriptionObserver",
}