import { inject } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { IComponentProps } from "../main/interfaces/IComponentProps";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
export class AOBenefits extends React.Component<Partial<IComponentProps>>{

    public render() {

        const vm = this.props.viewStore.userPortalViewModel.SoftwareUsageViewModel;

        let tile2Description: string = (vm.UseWindows10Tiles) ? "Streaming Performance" : "Windows Performance";
        let tile2Class: string = (vm.UseWindows10Tiles) ? "streaming" : "windows"
        let tile3Description: string = (vm.UseWindows10Tiles) ? "Software Performance" : "Startup Performance";
        return <div className="aoBenefits">
            <h4 className="sectionTitle">Optimizations</h4>

            <ul id="aoFeatures">
                <li>
                    <h4>Internet Performance</h4>
                    <div className="internetPerformanceIcon aoIcon" />
                    <p className="optimizationText"><strong>{vm.InternetOptimizations}</strong> optimizations<br />active</p>
                </li>
                <li>
                    <h4>{tile2Description}</h4>
                    <div className={`tile2Icon aoIcon ${tile2Class}`} />
                    <p className="optimizationText"><strong>{vm.WindowsOptimizations}</strong> optimizations<br />active</p>
                </li>
                <li>
                    <h4>{tile3Description}</h4>
                    <div className="startupPerformanceIcon aoIcon" />
                    <p className="optimizationText"><strong>{vm.StartupOptimizations}</strong> optimizations<br />active</p>
                </li>
            </ul>
        </div>;
    }
}