import { inject, observer } from "mobx-react";
import * as React from "react";
import { GoogleAnalytics } from "../core/classes/Analytics";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class BillingHistoryView extends React.Component<Partial<IComponentProps>>{

    constructor(props) {
        super(props);

        this.onInvoiceClicked = this.onInvoiceClicked.bind(this);
        this.onGoBackClicked = this.onGoBackClicked.bind(this);
    }

    public render() {
        try {
            const invoices = this.props.viewStore.userPortalViewModel.AccountViewModel.Invoices.slice(0, 15);
            let flip = false;

            return <div className="billingHistoryPanel appPanel detailed defaultMaterial">
                <h2 className="panelTitle">Billing History</h2>
                <div className="invoiceListHeaderWrapper">
                    <p className="billingHistoryHeader left">Billed Date</p>
                    <p className="billingHistoryHeader right">Invoice</p>
                </div>
                <ul className="invoiceList">
                    {invoices.map((object, i) => {

                        let firstOrLast = "";
                        if (i === 0)
                            firstOrLast = "first";
                        if (i === invoices.length - 1)
                            firstOrLast = "last";

                        let retval = <li className={`invoiceRow ${firstOrLast} ${flip ? "white" : ""}`} key={i}>
                            <p className="invoiceDate">{object.InvoiceDate}</p>
                            <button type="button" className="invoiceLink linkButton" onClick={() => this.onInvoiceClicked(object.PdfDownloadUrl)}>Download/View</button>
                        </li>

                        flip = !flip;

                        return retval;
                    })}
                </ul>
                <div className="appButton blue billingDetailGoBackButton" onClick={this.onGoBackClicked}>
                    <p className="appButton__buttonText">Go Back</p>
                </div>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering BillingHistoryView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private onInvoiceClicked(invoiceUrl: string) {
        const action = AppActionHelpers.CreateAction(AppActions.OPEN_INVOICE, invoiceUrl);
        this.props.dispatcher.DispatchAction(action);
    }

    private onGoBackClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.SubscriptionDetail);
        this.props.dispatcher.DispatchAction(action);
    }
}