import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../../core/classes/AppContext";
import { IComponentProps } from "../interfaces/IComponentProps";
import { LoadingView } from "./LoadingView";
import { UI } from "./UI";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export default class AppLoadWrapper extends React.Component<Partial<IComponentProps>, undefined> {
    constructor(props) {
        super(props);
    }

    public render() {
        const isInitialized = this.props.viewStore.isInitialized;

        if (!isInitialized) {
            return <LoadingView loadingMessage="Loading..." />
        }
        return <UI />;
    }
}