import { observer } from "mobx-react";
import * as React from "react";
// @ts-ignore
import defaultPC from "../../assets/img/defaultPC.png";
// @ts-ignore
import coverageDateIcon from "../../assets/img/icon_coverageDate.png";
// @ts-ignore
import monitoringIcon from "../../assets/img/icon_monitoring.png";
// @ts-ignore
import osIcon from "../../assets/img/icon_os.png";
// @ts-ignore
import ramIcon from "../../assets/img/icon_ram.png";
// @ts-ignore
import storageIcon from "../../assets/img/icon_storage.png";
import { MachineMonitoringStatus } from "../models/enums/MachineMonitoringStatus";
import { MachineViewModel } from "../viewModels/MachineViewModel";

interface ISummaryPaneProps {
    vm: MachineViewModel;
    onSelectAnotherPC: () => void;
    onAddAnotherPC: () => void;
    onDeactivatePC: () => void;
    isLastMachine: boolean;
}

interface ISummaryPaneState {
    imageLoadError: boolean
}

@observer
export class SummaryPane extends React.Component<Partial<ISummaryPaneProps>, ISummaryPaneState>{
    constructor(props) {
        super(props);

        this.state = { imageLoadError: false };
    }

    public render() {

        let monitorText = "Actively Monitoring";
        if (this.props.vm.monitoringStatus === MachineMonitoringStatus.Inactive) {
            monitorText = "Monitoring Inactive";
        }

        return <div id="summary_pane" className="summary_pane overview_pane">
            <div id="summary_pane_header" className="summary_pane_header">
                <h2 id="summary_pane_header_text" className="summary_pane_header_text">Your PC Summary</h2>
                <div id="summary_pane_header_buttonContainer" className="summary_pane_header_buttonContainer">
                    <button disabled={this.props.isLastMachine} type="button" id="select_pc_button" className="select_pc_button appButton blue" onClick={this.props.onSelectAnotherPC}>
                        <p className="appButton__buttonText">Select Another PC</p>
                    </button>
                    <button type="button" id="add_pc_button" className="add_pc_button appButton blue" onClick={this.props.onAddAnotherPC}>
                        <p className="appButton__buttonText">Add Another PC</p>
                    </button>
                </div>
            </div>
            <div id="summary_pane_content_container" className="summary_pane_content_container">
                <div id="summary_pane_pc_info_container" className="summary_pane_pc_info_container">
                    <h3 id="summary_pane_pcName" className="summary_pane_pcName">{this.props.vm.machineName}</h3>
                    {this.props.vm.machineImageUrl != null && !this.state.imageLoadError ? (
                        <img id="summary_pane_pcImage" className="summary_pane_pcImage" src={this.props.vm.machineImageUrl}
                            onError={() => { this.setState({ imageLoadError: true }); }}
                            onLoadedData={() => { this.setState({ imageLoadError: false }); }} />
                    ) : (
                            <img id="defaultPCImage" className="defaultPCImage" src={defaultPC} />
                        )}
                    {!this.props.isLastMachine &&
                        <button type="button" id="summary_pane_deactivateButton" className="summary_pane_deactivateButton linkButton" onClick={this.props.onDeactivatePC}>Deactivate</button>
                    }
                </div>
                <div id="summary_pane_divider" className="summary_pane_divider" />
                <div id="summary_pane_pc_specs_container" className="summary_pane_pc_specs_container">
                    <div id="specEntry_os" className="specEntry_container">
                        <div id="specEntry_iconContainer" className="specEntry_iconContainer">
                            <img className="osIcon" id="osIcon" src={osIcon} />
                        </div>
                        <p id="specEntry_text" className="specEntry_text">
                            {this.props.vm.os}
                        </p>
                    </div>
                    <div id="specEntry_ram" className="specEntry_container">
                        <div id="specEntry_iconContainer" className="specEntry_iconContainer">
                            <img className="ramIcon" id="ramIcon" src={ramIcon} />
                        </div>
                        <p id="specEntry_text" className="specEntry_text">
                            {this.props.vm.displayMemorySize}
                        </p>
                    </div>
                    <div id="specEntry_storage" className="specEntry_container">
                        <div id="specEntry_iconContainer" className="specEntry_iconContainer">
                            <img className="storageIcon" id="storageIcon" src={storageIcon} />
                        </div>
                        <p id="specEntry_text" className="specEntry_text">
                            {this.props.vm.displayStorageSize}
                        </p>
                    </div>
                    <div id="specEntry_startDate" className="specEntry_container">
                        <div id="specEntry_iconContainer" className="specEntry_iconContainer">
                            <img className="startDateIcon" id="startDateIcon" src={coverageDateIcon} />
                        </div>
                        <p id="specEntry_text" className="specEntry_text">
                            {this.props.vm.displayCoverageStartDate}
                        </p>
                    </div>
                    <div id="specEntry_monitoring" className="specEntry_container">
                        <div id="specEntry_iconContainer" className="specEntry_iconContainer">
                            <img className="specEntry_monitoring" id="specEntry_monitoring" src={monitoringIcon} />
                        </div>
                        <p id="specEntry_text" className="specEntry_text">
                            Status:
                        </p>
                        <p id="specEntry_text monitorText" className="specEntry_text monitorText">
                            {monitorText}
                        </p>
                    </div>
                </div>
            </div>
        </div >
    }
}