import { inject, observer } from "mobx-react";
import * as React from "react";
import { GoogleAnalytics } from "../core/classes/Analytics";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
// @ts-ignore
import userIcon from "../../assets/img/icn-user-settings.png";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class PersonalInformationView extends React.Component<Partial<IComponentProps>, undefined>{

    constructor(props) {
        super(props);

        this.onUpdatePersonalInformationClicked = this.onUpdatePersonalInformationClicked.bind(this);
    }

    public render() {
        try {

            const name = this.props.viewStore.userFullName;
            const email = this.props.viewStore.user.Email;

            return <div className="personalInformationPanel appPanel defaultMaterial">
                <h2 className="panelTitle">
                    <img src={userIcon} className="userIcon" />
                    Personal Information
                </h2>
                <button type="button" className="updateLink linkButton" onClick={this.onUpdatePersonalInformationClicked}>Update</button>
                <div className="personalInfoFieldContainer">
                    <p className="personalInfoLabel">Name:</p>
                    <div className="spacer" />
                    <p className="personalInfoText">{name}</p>
                </div>
                <div className="personalInfoFieldContainer">
                    <p className="personalInfoLabel">Email:</p>
                    <div className="spacer" />
                    <p className="personalInfoText">{email}</p>
                </div>
                <div className="personalInfoFieldContainer">
                    <p className="personalInfoLabel">Password:</p>
                    <div className="spacer" />
                    <p className="personalInfoText">*****</p>
                </div>
            </div>
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering PersonalInformationView - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private onUpdatePersonalInformationClicked() {
        GoogleAnalytics.TrackEvent("AccountPortal", "Update Personal Info", "Update Personal Info Clicked");
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.UpdateUserInfo);
        this.props.dispatcher.DispatchAction(action);
    }
}