import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { ISubscriptionViewModel } from "../models/ISubscriptionViewModel";
import { ContactView } from "./ContactView";
import { DriverQuestionForm } from "./DriverQuestionForm";
import { MessageSentForm } from "./MessageSentForm";
import { RefundChallengeView } from "./RefundChallengeView";
import { RegistrationKeyForm } from "./RegistrationKeyForm";
import { SupportHome } from "./SupportHome";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class SupportView extends React.Component<Partial<IComponentProps>, undefined>
{
    constructor(props) {
        super(props);
    }

    public render() {
        try {
            return this.GetDisplay();
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering Support Screen - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private GetDisplay() {
        const screen = this.props.viewStore.activeScreen;

        switch (screen) {
            case AppViews.Support:
                return <SupportHome viewStore={this.props.viewStore} dispatcher={this.props.dispatcher} exceptionReporter={this.props.exceptionReporter} />;
            case AppViews.QuestionAboutBill:
            case AppViews.InstallDriverProblem:
            case AppViews.SoftwareInstallProblem:
            case AppViews.DriverQuestionForm:
            case AppViews.ContactForm:
                return <ContactView />;
            case AppViews.RegistrationKeyForm:
                return <RegistrationKeyForm />;
            case AppViews.MessageSent:
                return <MessageSentForm />;
            case AppViews.RequestARefundForm:
                return <div className="wrapper">
                    <RefundChallengeView />
                </div>;
        }
    }
}