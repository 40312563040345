import { inject, injectable } from "inversify";
import { IExceptionReporter } from "../../api/interfaces/IExceptionReporter";
import { TYPES } from "../../core/enumerations/TYPES";
import { IForgotPasswordObserver } from "../interfaces/IForgotPasswordObserver";
import { IObserverController } from "../interfaces/IObserverController";
import { IResetPasswordObserver } from "../interfaces/IResetPasswordObserver";
import { IViewObserver } from "../interfaces/IViewObserver";
import { KnowledgeBaseObserver } from "./KnowledgeBaseObserver";
import { IKnowledgeBaseObserver } from "../interfaces/IKnowledgeBaseObserver";

@injectable()
export class ObserverController implements IObserverController {

    private readonly viewObserver: IViewObserver;
    private readonly cancelSubscriptionObserver: ICancelSubscriptionObserver;
    private readonly exceptionReporter: IExceptionReporter;

    constructor(@inject(TYPES.ViewObserver) viewObserver: IViewObserver,
        @inject(TYPES.CancelSubscriptionObserver) cancelSubscriptionObserver: ICancelSubscriptionObserver,
        @inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter) {

        this.exceptionReporter = exceptionReporter;
        this.viewObserver = viewObserver;
        this.cancelSubscriptionObserver = cancelSubscriptionObserver;
    }
}

@injectable()
export class ForgotPasswordObserverController implements IObserverController {
    private readonly forgotPasswordObserver: IForgotPasswordObserver;
    private readonly exceptionReporter: IExceptionReporter;

    constructor(@inject(TYPES.ForgotPasswordObserver) forgotPasswordObserver: IForgotPasswordObserver,
        @inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter) {

        this.exceptionReporter = exceptionReporter;
        this.forgotPasswordObserver = forgotPasswordObserver;
    }
}

@injectable()
export class ResetPasswordObserverController implements IObserverController {
    private readonly resetPasswordObserver: IResetPasswordObserver;
    private readonly exceptionReporter: IExceptionReporter;

    constructor(@inject(TYPES.ResetPasswordObserver) resetPasswordObserver: IResetPasswordObserver,
        @inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter) {

        this.exceptionReporter = exceptionReporter;
        this.resetPasswordObserver = resetPasswordObserver;
    }
}

@injectable()
export class KnowledgeBaseObserverController implements IObserverController {
    private readonly knowledgeBaseObserver: IKnowledgeBaseObserver;
    private readonly exceptionReporter: IExceptionReporter;

    constructor(@inject(TYPES.KnowledgeBaseObserver) knowledgeBaseObserver: IKnowledgeBaseObserver,
        @inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter) {

        this.exceptionReporter = exceptionReporter;
        this.knowledgeBaseObserver = knowledgeBaseObserver;
    }
}