import { inject, observer } from "mobx-react";
import * as React from "react";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { IComponentProps } from "../main/interfaces/IComponentProps";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class SubscriptionSelect extends React.Component<Partial<IComponentProps>, undefined>{

    constructor(props) {
        super(props);

        this.onSubscriptionChanged = this.onSubscriptionChanged.bind(this);
    }

    public render() {
        try {

            const subscriptionList = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions;
            const selectedSubscription = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions.find(s => s.SubscriptionId === this.props.viewStore.activeSubscriptionId);
            const activeSubscriptionIndex = subscriptionList.indexOf(selectedSubscription);

            if (subscriptionList.length < 2) {
                return null;
            }

            return <select className="subscriptionSelect" defaultValue={activeSubscriptionIndex.toString()} onChange={this.onSubscriptionChanged}>
                {subscriptionList.map((obj, i) => {
                    return <option className="subscriptionSelectOption" key={i} value={i.toString()}>
                        Subscription - {obj.SubscriptionNumber}
                    </option>;
                })}
            </select>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering SubscriptionSelect - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    private onSubscriptionChanged() {
        const newSubscriptionIndex: number = +$('.subscriptionSelect').val();
        const newSubscriptionId = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions[newSubscriptionIndex].SubscriptionId;

        const action = AppActionHelpers.CreateAction(AppActions.CHANGE_ACTIVE_SUBSCRIPTION, newSubscriptionId);
        this.props.dispatcher.DispatchAction(action);
    }
}