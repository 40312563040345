import { Page } from "driversupport.frontend.common";
import { inject, observer } from "mobx-react";
import * as React from "react";
// @ts-ignore
import loader from "../../../assets/img/loader.gif";
import { GoogleAnalytics } from "../../core/classes/Analytics";
import { IProviderWrapper } from "../../core/classes/AppContext";
import { AppActionHelpers } from "../../core/classes/Helpers";
import { ViewUtils } from "../../core/classes/ViewUtils";
import { GenericErrorView } from "../../core/components/GenericErrorView";
import { AppActions } from "../../core/enumerations/AppActions";
import { AppViews } from "../../core/enumerations/AppViews";
import { LoginView } from "../../login/LoginView";
import { SubscriptionView } from "../../overview/SubscriptionView";
import { PCSelectorView } from "../../pcSelector/PCSelectorView";
import { SupportView } from "../../support/SupportView";
import { IComponentProps } from "../interfaces/IComponentProps";
import { Footer } from "./Footer";
import { HeaderBar } from "./HeaderBar";
import { LoadingView } from "./LoadingView";
import { MainMenu } from "./MainMenu";

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class UI extends React.Component<Partial<IComponentProps>>
{

    constructor(props) {
        super(props);

        window.onpopstate = () => { this.onHistoryPopState() };
    }

    public render() {
        try {
            const activeScreen = this.props.viewStore.activeScreen;

            if (activeScreen === AppViews.Loading) {
                return <div id="mainWrapper">
                    <div id="main">
                        <LoadingView loadingMessage="Loading..." />
                        <div className="clear"></div>
                    </div>
                </div>;
            }

            this.pushHistory();
            return <div id="mainWrapper">
                {this.props.viewStore.isCancellingSubscription &&
                    <div className="workingModalWrapper" id="workingModalWrapper">
                        <div className="workingModalBacker" id="workingModalBacker" />
                        <div className="workingModal" id="workingModal">
                            <img className="loadingAnimation" id="workingModalLoadingAnimation" src={loader} />
                            <p className="workingText" id="workingModalText">Cancelling Your Subscription</p>
                        </div>
                    </div>
                }
                {this.props.viewStore.isDiscountingSubscription &&
                    <div className="workingModalWrapper" id="workingModalWrapper">
                        <div className="workingModalBacker" id="workingModalBacker" />
                        <div className="workingModal" id="workingModal">
                            <img className="loadingAnimation" id="workingModalLoadingAnimation" src={loader} />
                            <p className="workingText" id="workingModalText">Discounting Your Subscription</p>
                        </div>
                    </div>
                }
                {this.props.viewStore.isInErrorState &&
                    <GenericErrorView />
                }

                <div id="main">
                    <HeaderBar showLogoff={true} />
                    <MainMenu />
                    {this.GetUIDisplay()}
                    <div className="clear"></div>
                </div>
                <Footer />
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering UserPortal UI - ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportCriticalException(outerex);
            return null;
        }
    }

    public componentDidMount() {
        window.addEventListener("resize", UI.positionFooter);
        UI.positionFooter();
    }

    public componentDidUpdate() {

        UI.positionFooter();
    }

    public componentWillUnmount() {
        window.removeEventListener("resize", UI.positionFooter);
    }

    public static positionFooter() {
        //footer positioning
        let mainDivHeight = $("#main").outerHeight() + 140;
        let hPos = (window.innerHeight > mainDivHeight) ? window.innerHeight : mainDivHeight;
        $('#footer').offset({ top: hPos - 140, left: 0 });
    }

    private GetUIDisplay(): JSX.Element {
        const activeScreen = this.props.viewStore.activeScreen;

        switch (activeScreen) {
            case AppViews.Overview:
            case AppViews.SubscriptionDetail:
            case AppViews.UpdatePayment:
            case AppViews.UpdatePaymentAndResume:
            case AppViews.CancelSubscription:
            case AppViews.CancelSubscription2:
            case AppViews.CancelSubscription3:
            case AppViews.CancelSubscription4:
            case AppViews.CancelSubscription5:
            case AppViews.SelectPayment:
            case AppViews.PreCancel:
            case AppViews.ResolvThankYou:
            case AppViews.ViewInvoices:
            case AppViews.PaymentDeclined:
            case AppViews.UpdateUserInfo:
                return <SubscriptionView />
            case AppViews.Support:
            case AppViews.QuestionAboutBill:
            case AppViews.InstallDriverProblem:
            case AppViews.SoftwareInstallProblem:
            case AppViews.DriverQuestionForm:
            case AppViews.MessageSent:
            case AppViews.RegistrationKeyForm:
            case AppViews.ContactForm:
            case AppViews.RequestARefundForm:
                return <SupportView />
            case AppViews.Login:
                return <LoginView />
            case AppViews.PCSelector:
                return <PCSelectorView />
        }
    }

    private removeURLParameter(url, parameter) {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0;) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
        }
        return url;
    }

    private onHistoryPopState() {

        const isLoggedIn = this.props.viewStore.isLoggedIn;
        let screen: AppViews;
        let activeSubscriptionId: string;



        //if this pop came from an application click we can pull the screen from state
        if (history.state) {
            screen = history.state.activeScreen as AppViews;
            activeSubscriptionId = history.state.activeSubscriptionId;
        }
        else {
            //if this was a manual navigation we need to pull the screen from hash
            screen = AppViews[Page.GetPageHashValue()];
            activeSubscriptionId = Page.GetParameterByName("sid");

            if (!screen) {
                //something was munged in the manually inputted url just toss them to overview
                screen = AppViews.Overview;
            }
        }

        //dont let them go back to screens once they've logged out
        if (!isLoggedIn && ViewUtils.doesScreenRequireLogin(screen)) {
            this.RedirectTo(AppViews.Login);
            return;
        }

        //if (isLoggedIn && this.props.viewStore.currentMachineIndex == null) {
        //    this.RedirectTo(AppViews.PCSelector);
        //    return;
        //}

        //dont take them to a login form if they are already logged in
        if (screen == AppViews.Login && isLoggedIn) {
            this.RedirectTo(AppViews.Overview);
            return;
        }

        //restrict browser nav to these pages because they depend on state that may no longer be valid
        if (!ViewUtils.isScreenHistoryNavigatable(screen)) {
            this.RedirectTo(AppViews.SubscriptionDetail);
            return;
        }

        this.RedirectTo(screen);
        if (activeSubscriptionId) {
            const action = AppActionHelpers.CreateAction(AppActions.CHANGE_ACTIVE_SUBSCRIPTION, activeSubscriptionId);
            this.props.dispatcher.DispatchAction(action);
        }
    }

    private pushHistory() {
        const activeScreen = this.props.viewStore.activeScreen;
        const activeSubscriptionId = this.props.viewStore.activeSubscriptionId;
        const currentMachineIndex = this.props.viewStore.currentMachineIndex;
        const isLoggedIn = this.props.viewStore.isLoggedIn;

        const title = `Solve iQ Account Portal ${AppViews[activeScreen]}`;

        let queryString = "";

        if (activeSubscriptionId && isLoggedIn) {
            queryString = `?sid=${activeSubscriptionId}`;

            if (currentMachineIndex != null) {
                queryString = `${queryString}&machineIndex=${currentMachineIndex}`;
            }
        }

        const hash = `${queryString}#${AppViews[activeScreen]}`;

        try {
            GoogleAnalytics.TrackVirtualPageView(`accountportal/${AppViews[activeScreen]}`);
        }
        catch (ex) {
            this.props.exceptionReporter.ReportException(ex);
        }
        if (!history.state) {
            history.replaceState({ activeScreen: activeScreen, activeSubscriptionId: activeSubscriptionId, activeMachineIndex: currentMachineIndex }, title, window.location.pathname + hash);
        }
        else if (history.state.activeScreen != activeScreen || history.state.activeSubscriptionId != activeSubscriptionId) {
            history.pushState({ activeScreen: activeScreen, activeSubscriptionId: activeSubscriptionId, activeMachineIndex: currentMachineIndex }, title, window.location.pathname + hash);
        }
    }

    private RedirectTo(screen: AppViews) {
        const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, screen);
        this.props.dispatcher.DispatchAction(action);
    }
}