import { inject, injectable } from "inversify";
import { IDataGetResponse, IDataPostResponse } from "../../core/classes/Responses";
import { TYPES } from "../../core/enumerations/TYPES";
import { ILoginStatus } from "../../models/ILoginStatus";
import { ILoginViewModel } from "../../models/ILoginViewModel";
import { ILogoffViewModel } from "../../models/ILogoffViewModel";
import { IUserInfoResponse } from "../../models/IUserInfoResponse";
import { IUserLicenseResponse } from "../../models/IUserLicenseResponse";
import { IDhqUser, IUserLicense } from "../../models/User";
import { UserDataUpdate } from "../../models/UserDataUpdate";
import { IAuthApi } from "../interfaces/IAuthApi";
import { IExceptionReporter } from "../interfaces/IExceptionReporter";
import { PasswordPayload } from "../../models/PasswordPayload";
import { IPasswordUpdateResponse } from "../../models/IPasswordUpdateResponse";
import { IPasswordEmailSentResponse } from "../../models/IPasswordEmailSentResponse";

declare var authControllerPath;

@injectable()
export class AuthApi implements IAuthApi {

    private exceptionReporter: IExceptionReporter

    constructor(@inject(TYPES.ExceptionReporter) exceptionReporter: IExceptionReporter) {

        this.exceptionReporter = exceptionReporter;
    }

    public loginAsync(username: string, password: string, rememberMe: boolean): Promise<ILoginViewModel> {

        const payload = JSON.stringify({ username: username, password: password, rememberMe: rememberMe });
        const ajaxPath = `${authControllerPath}Login`;

        return fetch(ajaxPath, {
            method: "POST",
            body: payload,
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataPostResponse;
                return {
                    base: {} as ILoginViewModel,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during Login");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to Login failed with status: ${statusCode}`), e);
                return {
                    base: {} as ILoginViewModel,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode
                }
            });
    }

    public getUserAsync(): Promise<IUserInfoResponse> {

        const ajaxPath = `${authControllerPath}GetUser`;

        return fetch(ajaxPath)
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataGetResponse;
                return {
                    base: {} as IUserInfoResponse,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    userInfo: ro.Success ? JSON.parse(ro.Payload) as IDhqUser : null,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during GetUser");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to GetUser failed with status: ${statusCode}`), e);
                return {
                    base: {} as IUserInfoResponse,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                    userInfo: null
                }
            });
    }

    public checkIsLoggedInAsync(): Promise<ILoginStatus> {

        const ajaxPath = `${authControllerPath}CheckIsLoggedIn`;

        return fetch(ajaxPath)
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataGetResponse;
                return {
                    base: {} as ILoginStatus,
                    errorMessage: ro.Message,
                    isSuccessfulAction: false,
                    statusCode: 200,
                    isLoggedIn: JSON.parse(ro.Payload) as boolean
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during CheckIsLoggedIn");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to CheckIsLoggedIn failed with status: ${statusCode}`), e);
                return {
                    base: {} as ILoginStatus,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                    isLoggedIn: false
                }
            });
    }

    public getUserLicenseAsync(registrationKey: string): Promise<IUserLicenseResponse> {

        const ajaxPath = `${authControllerPath}GetReconciledLicense?registrationKey=${registrationKey}`;

        return fetch(ajaxPath)
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataGetResponse;
                return {
                    base: {} as IUserLicenseResponse,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    license: ro.Success ? JSON.parse(ro.Payload) as IUserLicense : null,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during GetReconciledLicense");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to GetReconciledLicense failed with status: ${statusCode}`), e);
                return {
                    base: {} as IUserLicenseResponse,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                    license: null
                }
            });
    }

    public logOffAsync(): Promise<ILogoffViewModel> {

        const ajaxPath = `${authControllerPath}LogOff`;

        return fetch(ajaxPath)
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataGetResponse;
                return {
                    base: {} as ILogoffViewModel,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during LogOff");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to LogOff failed with status: ${statusCode}`), e);
                return {
                    base: {} as IUserLicenseResponse,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode
                }
            });
    }

    public updateUserInfoAsync(updatedUserData: UserDataUpdate): Promise<IUserInfoResponse> {

        const ajaxPath = `${authControllerPath}UpdateUser`;

        return fetch(ajaxPath, {
            method: "POST",
            body: JSON.stringify(updatedUserData),
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataGetResponse; // use an IDataGetResponse because even those its a post action we're getting data back from the server
                return {
                    base: {} as IUserInfoResponse,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    userInfo: ro.Success ? JSON.parse(ro.Payload) as IDhqUser : null,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during UpdateUser");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to UpdateUser failed with status: ${statusCode}`), e);
                return {
                    base: {} as IUserInfoResponse,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                    userInfo: null
                }
            });
    }


    public setNewPassword(passwordPayload: PasswordPayload): Promise<IPasswordUpdateResponse> {

        const ajaxPath = `${authControllerPath}SetNewPassword`;

        return fetch(ajaxPath, {
            method: "POST",
            body: JSON.stringify(passwordPayload),
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataPostResponse;
                return {
                    base: {} as IPasswordUpdateResponse,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during UpdateUser");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to UpdateUser failed with status: ${statusCode}`), e);
                return {
                    base: {} as IPasswordUpdateResponse,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                }
            });
    }

    public sendPasswordResetEmail(sendEmail: string): Promise<IPasswordEmailSentResponse> {

        const ajaxPath = `${authControllerPath}SendPasswordResetEmail`;

        return fetch(ajaxPath, {
            method: "POST",
            body: JSON.stringify({ email: sendEmail }),
            headers: { "Content-Type": "application/json" }
        })
            .then(this.validateResponse)
            .then(r => r.json())
            .then(responseObject => {
                const ro = responseObject as IDataPostResponse;
                return {
                    base: {} as IPasswordEmailSentResponse,
                    errorMessage: ro.Message,
                    isSuccessfulAction: ro.Success,
                    statusCode: 200
                }
            })
            .catch(e => {
                const statusCode = parseInt(e.message);
                if (isNaN(statusCode)) {
                    // if we can't read the status code then something very wrong happened.
                    const error = new Error("An error occurred during UpdateUser");
                    this.exceptionReporter.ReportException(error, e);
                    throw error;
                }

                // log this error condition, but allow the caller to determine how to ultimately handle it
                this.exceptionReporter.ReportException(new Error(`Call to UpdateUser failed with status: ${statusCode}`), e);
                return {
                    base: {} as IPasswordEmailSentResponse,
                    errorMessage: e.message,
                    isSuccessfulAction: false,
                    statusCode: statusCode,
                }
            });
    }

    private validateResponse(response: Response) {
        if (!response.ok) {
            throw Error(response.status.toString());
        }
        return response;
    }
}