import * as React from "react";
// @ts-ignore
import logo from "../../../assets/img/solveiq/logoWhite.png";

export class Footer extends React.Component{
    public render() {
        const year = new Date().getUTCFullYear();
        return <div id="footer">
            <div className="wrapper">
                <img src={logo} className="logo" />
                <p className="footerText floatRight copyrightText">Copyright {year} Solve iQ. All rights reserved. Solve iQ, the Solve iQ logo, and SolveiQ.com are trademarks of Solve iQ and its affiliated companies</p>
                <div className="clear"></div>
                <a className="footerLink floatRight" href="https://www.trustpilot.com/review/solveiq.com" target="_blank">Customer Reviews</a>
                <div className="footerDivider floatRight"></div>
                <a className="footerLink floatRight" href="https://www.solveiq.com/software-principles/" target="_blank">Software Principles</a>
                <div className="footerDivider floatRight"></div>
                <a className="footerLink floatRight" href="https://www.solveiq.com/privacy-policy/" target="_blank">Privacy Policy</a>
                <div className="footerDivider floatRight"></div>
                <a className="footerLink floatRight" href="https://www.solveiq.com/terms-and-conditions/" target="_blank">Terms And Conditions</a>
                <div className="footerDivider floatRight"></div>
                <a className="footerLink floatRight" href="https://www.solveiq.com/cookie-policy/" target="_blank">Cookie Policy</a>
                <div className="footerDivider floatRight"></div>
                <a className="footerLink floatRight" href="https://www.solveiq.com/uninstall-instructions/" target="_blank">Uninstall Instructions</a>
            </div>
        </div>;
    }
}