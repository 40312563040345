import { Checkbox, FormControlLabel, CheckboxProps } from "@material-ui/core";
import { StylesProvider, withStyles } from "@material-ui/core/styles";
import * as React from "react";
import { green } from "@material-ui/core/colors";

export interface IDsOneCheckboxProps {
    initial: boolean;
    labelText: string;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

export const DsOneCheckbox = (props: IDsOneCheckboxProps) => {


    const GreenCheckbox = withStyles({
        root: {
            color: "#007DA0",
            '&$checked': {
                color: "#007DA0",
            },
        },
        checked: {},
    })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

   

    const [checked, setChecked] = React.useState(props.initial);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (props.handleChange) {
            props.handleChange(event, event.target.checked);
        }
    };

    return <StylesProvider injectFirst>
        <FormControlLabel
            control={
                <GreenCheckbox id="dsoneCheckBox"
                    checked={checked}
                    onChange={handleChange}
                />
            }
            label={props.labelText}
            labelPlacement="end"
            />
            
    </StylesProvider>
}