import { inject, observer } from "mobx-react";
import * as React from "react";
// @ts-ignore
import saleBanner from "../../assets/img/specials-bg.png";
import { IProviderWrapper } from "../core/classes/AppContext";
import { AppActionHelpers } from "../core/classes/Helpers";
import { AppActions } from "../core/enumerations/AppActions";
import { AppViews } from "../core/enumerations/AppViews";
import { IComponentProps } from "../main/interfaces/IComponentProps";
import { ISubscriptionViewModel } from "../models/ISubscriptionViewModel";
import { ContactPane } from "../overview/ContactPane";
import { CancelProgressBar } from "./CancelProgressBar";

declare var _kmq;

@inject((context: IProviderWrapper) => ({
    viewStore: context.appContext.Store.ViewStore,
    dispatcher: context.appContext.Dispatcher,
    exceptionReporter: context.appContext.ExceptionReporter
}))
@observer
export class CancelSubscription4 extends React.Component<Partial<IComponentProps>, undefined>{
    constructor(props) {
        super(props);
        this.onEndBenefitsClicked = this.onEndBenefitsClicked.bind(this);
        this.onKeepBenefitsClicked = this.onKeepBenefitsClicked.bind(this);

        _kmq.push(['record', "Cancel Step 4"]);
    }

    public render() {
        try {
            //know this is valid thanks to componentDidMount
            const selectedSubscription = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions.find((sub: ISubscriptionViewModel) => sub.SubscriptionId == this.props.viewStore.activeSubscriptionId);
            const price = parseFloat(selectedSubscription.SubscriptionPrice);
            const discountPrice = (price / 2).toString().match(/^\d+(?:\.\d{0,2})/)[0]; //truncate at 2 decimal places
            const discountString = "$" + selectedSubscription.SubscriptionPrice.replace(price.toString(), discountPrice);

            return <div className="cancelSubscriptionWrapper" id="cancelSubscriptionWrapper">
                <CancelProgressBar step={4} />
                <ContactPane />
                <div className="cancelRecoveryOfferWrapper appPanel defaultMaterial" id="cancelRecoveryOfferWrapper">
                    <img className="cancelRecoveryOffer" id="cancelRecoveryOffer" src={saleBanner} />
                    <span className="cancelRecoveryOfferPrice">{discountString}</span>
                </div>
                <div className="cancelContinueChallenge appPanel defaultMaterial" id="cancelContinueChallenge">
                    <h2 className="cancelContinueChallengeHeader" id="cancelContinueChallengeHeader">Are you sure you want to cancel and lose these benefits?</h2>
                    <div className="cancelButtonArea" id="cancelButtonArea">
                        <button type="button" className="appButton hollow endBenefitsButton cancelStepButton" id="endBenefitsButton" onClick={this.onEndBenefitsClicked}>End My Benefits</button>
                        <button type="button" className="appButton blue keepBenefitsButton cancelStepButton discountButton" id="keepBenefitsButton" onClick={this.onKeepBenefitsClicked}>WAIT! I Want the Special Offer of {discountString}</button>
                    </div>
                </div>
            </div>;
        }
        catch (ex) {
            let outerex = new Error(`Error Rendering cancel Subscription ${ex.message}`);
            outerex.stack = ex.stack;
            this.props.exceptionReporter.ReportCriticalException(outerex);
            //AppActions.applicationCriticalError();
            return null;
        }
    }

    public componentDidMount() {
        //prevent invalid subscription navigation and prevent navigation to this page when subscription is already cancelled
        let selectedSubscription = this.props.viewStore.userPortalViewModel.AccountViewModel.Subscriptions.find((sub: ISubscriptionViewModel) => sub.SubscriptionId == this.props.viewStore.activeSubscriptionId);
        if (!selectedSubscription || selectedSubscription.IsActive == false) {
            const action = AppActionHelpers.CreateAction(AppActions.SELECT_ACTIVE_SCREEN, AppViews.Overview);
            this.props.dispatcher.DispatchAction(action);
        }

        var price = parseFloat(selectedSubscription.SubscriptionPrice);

        //subscription price is already discounted, so don't offer the discount
        if (price < 9.9)
            this.onEndBenefitsClicked();
    }

    private onEndBenefitsClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.CANCEL_SUBSCRIPTION_ADVANCE, AppViews.Overview);
        this.props.dispatcher.DispatchAction(action);
    }

    private onKeepBenefitsClicked() {
        const action = AppActionHelpers.CreateAction(AppActions.DISCOUNT_SUBSCRIPTION, null);
        this.props.dispatcher.DispatchAction(action);
    }
}
