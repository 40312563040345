import { MachineMonitoringStatus } from "../models/enums/MachineMonitoringStatus";
import { DriverSummaryViewModel } from "./DriverSummaryViewModel";
import { SoftwareOptimizationSummaryViewModel } from "./SoftwareOptimizationSummaryViewModel";
import { FormatHelpers } from "../core/classes/Helpers";
import { ILicenseActivationMessage } from "../models/messages/ILicenseActivationMessage";

export class MachineViewModel {
    machineName: string;
    os: string;
    memorySize: number;
    //cpu: string;
    storageSize: number;
    monitoringStatus: MachineMonitoringStatus;
    coverageStartDate: Date;
    driverSummary: DriverSummaryViewModel;
    softwareOptimizationSummary: SoftwareOptimizationSummaryViewModel;
    machineImageUrl: string;
    licenseActivation: ILicenseActivationMessage;

    
    get displayMemorySize(): string {
        // RAM is calculated using non-si units
        return FormatHelpers.convertBytesToDisplay(this.memorySize, false) + " Ram";
    }
    
    get displayStorageSize(): string {
        // HD is calculated using si-units
        return FormatHelpers.convertBytesToDisplay(this.storageSize, true) + " of Storage";
    }

    get displayCoverageStartDate() {
        return "Coverage Start Date: " + FormatHelpers.converDateToDisplay(this.coverageStartDate);
    }
}